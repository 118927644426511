import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import ProfilePicture from '../../ProfilePicture/ProfilePicture'
import * as constants from '../../../js/constants'

function CustomRoleLine(props){
 


function openCustomRole(event){
	event.preventDefault()
    props.openCustomRole(props.customRole.id)
}


function checkBoxClicked(event){
	props.checkBoxClicked(props.customRole.id, event.target.checked)
}

return (
		<tr scope="row" style={{backgroundColor:'transparent', fontFamily:'Helvetica,sans-seriff'}}>
          <th scope="row" class="ps-4" style={{width:'50px'}}>
             <div class="form-check font-size-16"><input type="checkbox" class="form-check-input customRole-line-checkbox" onClick={checkBoxClicked} name={'checkbox-'+props.customRole.id} style={{border:'1px solid #A9A9A9'}}/></div>
          </th>
          <td onClick = {openCustomRole}>
	            <a href=""  style = {{ textDecoration: 'none', fontSize:'16px', marginLeft: '5px',fontWeight:'500',fontSize:'14px'}} >{props.customRole.name}</a>
	      </td>
	      <td style={{textDecoration: 'none', color:'#192A3E',fontSize:'14px'}}>{props.customRole.description === 'null' ? '--' : props.customRole.description }</td>	      
        <td style={{color:'#192A3E'}}>{props.customRole.userCount}</td>        
      </tr> 	        
    );

}

export default CustomRoleLine;