import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import { ToastContainer, toast } from 'react-toastify';
import TicketCreateSuccessMessage from './TicketCreateSuccessMessage'

function CreateTicketForm(props){  
  const navigate = useNavigate()  
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const dropdownRef = useRef(null)
  const reporterRef = useRef(null)
  const descriptionRef = useRef(null)
  const REPORTER_DROP_DOWN_INPUT_POS = 0;
  const REPORTER_DROP_DOWN_MENU_POS = 1;
  const REPORTER_DROP_DOWN_HIDDEN_INPUT_POS = 2;
  
    function onSelectDropdown(event,id, name,email){
      nameRef.current.value = name
      reporterRef.current = id    
      emailRef.current.value = email
      dropdownRef.current.style.display = 'none'
      emailRef.current.disabled=true      
  }

function addChildToDropdown(element, event){
      let aTag = document.createElement('a')
      aTag.name=element.id
      // aTag.innerHTML = element.name //+';'+element.email 
      let outerDiv = document.createElement('div')
      let innerLeftDiv = document.createElement('div')
      let innerRightDiv = document.createElement('div')
      innerLeftDiv.innerHTML = element.name
      if(element.email === null || element.email === '')
        innerRightDiv.innerHTML=''
      else
        innerRightDiv.innerHTML=element.email
      innerLeftDiv.style.float='left'      
      innerLeftDiv.classList.add('add-ellipsis')
      innerLeftDiv.style.maxWidth='100px'
      innerRightDiv.style.float='right'
      innerRightDiv.classList.add('add-ellipsis')
      innerRightDiv.style.maxWidth='150px'
      outerDiv.appendChild(innerRightDiv)
      outerDiv.appendChild(innerLeftDiv)
      innerRightDiv.classList.add('call-window-create-ticket-dropdown-content')
      innerLeftDiv.classList.add('call-window-create-ticket-dropdown-content')
      aTag.appendChild(outerDiv)
      // aTag.onclick = onSelectDropdown()
      aTag.addEventListener('click', function(event){
          onSelectDropdown(event,element.id,element.name,element.email);
      });
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
  }
  // function addChildToDropdown(element, event){
  //     let aTag = document.createElement('a')
  //     aTag.name=element.id
  //     aTag.innerHTML = element.name 
  //     aTag.onclick = (event) => onSelectDropdown(event, element.name, element.id)
  //     event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].appendChild(aTag)
  //     event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
  // }  
  function handleReporter(event){   
      reporterRef.current = -1
      emailRef.current.value = ''
      emailRef.current.disabled=false  
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
      event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'block'
      event.target.parentElement.children[REPORTER_DROP_DOWN_HIDDEN_INPUT_POS].value = -1;
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.CONTACT_URL+"search?keyword="+event.target.value)
      .then(function (response) {        
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event)
          })
          if(response.data.length === 0){
            event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].innerHTML = ''
            event.target.parentElement.children[REPORTER_DROP_DOWN_MENU_POS].style.display = 'none'
          }
      }).catch(function (response) {
          console.log(response);
      });
  }

function handleSubmit(event){  
  console.log('handleSubmit '+reporterRef.current)
  event.preventDefault();
    let payload = {
      'summary': event.target.summary.value,
      'description': descriptionRef.current.innerHTML,
      'priority': event.target.priority.value,
      'channel' : 'call',
      'callId' : props.callId
   }
   let contact = {}
   if(event.target.summary.value === ''){
      toast.error('Ticket Summary is Mandatory')
        return
   }
   if(reporterRef.current === -1){
    if(event.target.reporter.value === '' || event.target.email.value === ''){
        toast.error('Reporter Name and Email are Mandatory')
        return
    }
   }
   if(reporterRef.current !== -1){
    contact['id'] = reporterRef.current
   }else{
    contact['fullName'] = event.target.reporter.value
    contact['email'] = event.target.email.value
   }
   payload['reporterContact'] = contact

   axiosClient.postRequest(constants.TICKET_URL, payload)
    .then(function (response) {                                  
       toast.success(<TicketCreateSuccessMessage ticketId={response.data.id}/>)            
       setTimeout(props.cancelTicket, 1000)
    }).catch((error) =>{
      console.log(error)
    });
}

	return(
<div style={{marginTop:'20px',border:'1px solid rgb(49, 68, 105)',padding:'20px'}}>
      <form onSubmit={handleSubmit} >
          <div  style={{fontSize:'14px', marginTop: "20px",color:'white',fontWeight:'bolder'}}>Summary:</div>               
          <input class='call-window-create-ticket-summary' name='summary' ></input>
          <div style={{ marginTop: "20px",color:'white',fontWeight:'bolder'}}>Description:</div>               
          <div contenteditable="true" class='call-window-create-ticket-description' name='description' ref={descriptionRef} />
          <div style={{ marginTop: "3%", color:'white', fontWeight:'bolder'}}>Reporter Name:</div>
          <div style={{position: 'relative' }}>
            <input type="text"  style={{border:'none', outline: 'none', width: "100%", backgroundColor:'rgb(49, 68, 105)', height:'36px',color: 'white'}} onChange={handleReporter} name="reporter" ref={nameRef}/>
            <div class='call-window-create-ticket-dropdown-menu' ref={dropdownRef}/>
            <input style= {{display: 'none'}} name = 'reporter-hidden-input' />
          </div>
          <div style={{ marginTop: "3%", color:'white', fontWeight:'bolder'}}>Reporter Email:</div>
          <div><input style={{color: 'white', border:'none',outline: 'none', width: '100%', backgroundColor:'rgb(49, 68, 105)', height:'36px'}} name='email' ref={emailRef}/></div>
          <div style={{ marginTop: "3%", color:'white', fontWeight:'bolder'}}>Priority: </div>          
          <div>
             <select name="priority" style={{border:'none',outline: 'none', width: '100%',padding: '5px',color:'white', backgroundColor:'rgb(49, 68, 105)', height:'36px'}}>
              <option value="Low">Low</option>      
              <option value="Medium">Medium</option>
              <option value="High">High</option>
              <option value="Critical">Critical</option>
              <option value="Escalated">Escalated</option>
          </select>        
        </div>
          <div>
            <button class='ss-button'  style={{ marginTop: "10px", marginLeft:'20px', color:'white', fontWeight:'bolder'}} class='create-button' onClick={props.cancelTicket}>Cancel</button>
            <button class='ss-button'  style={{ marginTop: "10px", marginLeft:'50px', color:'white', fontWeight:'bolder'}} class='create-button'>Create Ticket</button>
          </div>
        </form>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>        
      </div>

	)
}

export default CreateTicketForm;