import React from "react";
import { Handle, Position } from 'react-flow-renderer';


const DummyNode = ({ data }) => {
	console.log('DummyNode '+data.highlight)
  return (
    <div className={`dummy-node ${data.highlight ? "node-highlight" : ""}`}> 
      <div className="dummy-node-circle">+</div>
      <Handle type="target" position={Position.Top} />
      <Handle type="source" position={Position.Bottom} />
    </div>
  );
};

export default DummyNode;
