import React, { useEffect, useRef, useState} from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import { ToastContainer, toast } from 'react-toastify';
import DynamicForm from '../../CustomFields/js/DynamicForm'


function CreateCompanyForm(props){ 
  const [formData, setFormData] = useState([]);
  const [customFields, setCustomFields] = useState([]) 
  const [image, setImage] = useState(null)
  const [imageModified, setImageModified] = useState(false)
  const uploadImageRef = useRef(null)
  const imageRemoveRef = useRef(null)
  const workPhoneRef = useRef(null)
  const spinnerRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const numberRef = useRef(null)
  const addressRef = useRef(null)
  const websiteRef = useRef(null)
  const notesRef = useRef(null)
  const addWorkPhoneLink = useRef(null)
  const removeWorkPhoneLink = useRef(null)
  const addAddressLink = useRef(null)
  const removeAddressLink = useRef(null)
  const errorRef = useRef(null)
  const firstNameRef = useRef(null)  
  const navigate = useNavigate()
  let editFilled = false;
  useEffect(() => {   
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'
    removeWorkPhoneLink.current.style.visibility = 'hidden'
    imageRemoveRef.current.style.display='none'    
    if(isEdit() && !editFilled){
      editFilled = true;
      fillForm(); 
    }      
  },[])//use effect  


useEffect(() => { 
    if(!isEdit())  {
        axiosClient.getRequest(constants.CUSTOM_FIELDS+"display?name=Company")
        .then(function (response) {
            setCustomFields(response.data)
              let formDataTemp =   response.data.map((field) => ({
                    id: field.id,
                    name: field.name,
                    formValues: field.type === 'checkbox-group' ? '' : field.type === 'checkbox' ? false : '',
                    type: field.type,
                    apLabel: field.apLabel,
                    apMandatorySubmission: field.apMandatorySubmission,
                    apEditable:field.apEditable,
                    values:field.values
                  }))
               setFormData(formDataTemp) 
        }).catch(function (response) {
            console.log(response);
        });
    }
  },[])


function isEdit(){
  return props.editIdRef.current !== undefined && props.editIdRef.current !== -1
}
function fillForm(){
  let id = props.editIdRef.current
  axiosClient.getRequest(constants.COMPANY_URL+id)
    .then(function (response) {
        if(response.data.image !== 'null'){
          document.getElementById("drop-area").style.backgroundImage=`url(${response.data.link})`;
          uploadImageRef.current.style.display='none'
          imageRemoveRef.current.style.display='inline-block'
        }
        if(response.data.customFields !== 'null'){
          let   jsonCustomFields =  JSON.parse(response.data.customFields.replace(/'/g, '"'))
          setCustomFields(jsonCustomFields)
          let formDataTemp =   jsonCustomFields.map((field) => ({
                    id: field.id,
                    name: field.name,
                    formValues: field.formValues,
                    type: field.type,
                    apLabel: field.apLabel,
                    values: field.values
                  }))
          console.log('customFields '+ JSON.stringify(response.data))
          setFormData(formDataTemp) 
        }
        nameRef.current.value = (response.data.name === 'null' ? '' : response.data.name)
        emailRef.current.value = (response.data.email === 'null' ? '' : response.data.email)
        numberRef.current.value = (response.data.phoneNumber === 'null' ? '' : response.data.phoneNumber)
        addressRef.current.value = (response.data.address === 'null' ? '' : response.data.address.replaceAll("<br/>","\n"))
        websiteRef.current.value = (response.data.website === 'null' ? '' : response.data.website)
        notesRef.current.value = (response.data.notes === 'null' ? '' : response.data.notes.replaceAll("<br/>","\n"))
        if(response.data.workPhone1 !== 'null' && response.data.workPhone1 !== ''){
          workPhoneRef.current.lastChild.value = response.data.workPhone1
        }
        if(response.data.workPhone2 !== 'null' && response.data.workPhone2 !== ''){          
          if(workPhoneRef.current.lastChild.value !== '')
            addWorkPhoneField()
          workPhoneRef.current.lastChild.value = response.data.workPhone2
        }
        if(response.data.workPhone3 !== 'null' && response.data.workPhone3 !== ''){
          if(workPhoneRef.current.lastChild.value !== '')
              addWorkPhoneField()
          workPhoneRef.current.lastChild.value = response.data.workPhone3
        }
    }).catch(function (error) {
      console.log('error in get')
        if(error.response.data.error === "COMPANY_DOESNOT_EXIST"){
          toast.error('Company does not exist')
          props.closeCreateWindow()
          return
        }        
        console.log(error)
    });
}
function validateEmail(email){
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
}

function validatePhoneNumber(number){
  return true
  return String(number)
    .toLowerCase()
    .match(
      /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im
    );
}

function workPhonesHaveSameValue(event){
    let workPhones = [];
    if(event.target.workPhone.value !== '')workPhones.push(event.target.workPhone.value)
    if(event.target.workPhone1 !== undefined && event.target.workPhone1.value !== '')workPhones.push(event.target.workPhone1.value)
    if(event.target.workPhone2 !== undefined && event.target.workPhone2.value !== '')workPhones.push(event.target.workPhone2.value)
    if(workPhones.length < 2)  
      return false;
    if(workPhones.length == 2){
          return workPhones[0] === workPhones[1]
    }
    if(workPhones.length == 3){
          return (workPhones[0] === workPhones[1] ||
   workPhones[2] === workPhones[1] ||
   workPhones[0] === workPhones[2])
    }
}

function capitalizeWords(str){
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
function formDataToJson(formData) {
    const obj=[]
    formData.forEach((value, key) => {
        obj.push(value)      
    });
    return JSON.stringify(obj);
}

function handleSubmit(event){
    console.log("handleSubmit "+event.target.email.value)
    event.preventDefault()
    errorRef.current.style.display = 'none'
    
    let address = [{},{},{}]
    let company = {}
    //validations
    // if(event.target.email.value=== '' && event.target.mobileNumber.value === ''){
    //   console.log("Either of email or mobile error")
    //     errorRef.current.innerHTML = 'Either of email or mobile number are mandatory'
    //     errorRef.current.style.display = 'block'
    //     return
    // }
    if(event.target.email.value !== '' && !validateEmail(event.target.email.value)){
        errorRef.current.innerHTML = 'Invalid Email id'
        errorRef.current.style.display = 'block'
        return
    }
    if(event.target.mobileNumber.value !== '' && !validatePhoneNumber(event.target.mobileNumber.value)){              
        errorRef.current.innerHTML = 'Invalid Phone number'
        errorRef.current.style.display = 'block'
        return
    }
    if(workPhonesHaveSameValue(event)){
        console.log("Work Phones has same value")
        errorRef.current.innerHTML = 'Repeated Work Phone numbers present'
        errorRef.current.style.display = 'block'
        return        
    }
    spinnerRef.current.style.display = 'inline-block'
    //create payload
    let payload = { "name":capitalizeWords(event.target.name.value),
     "email" : event.target.email.value,
     "imageModified":imageModified,
     "workPhone1": event.target.workPhone.value,
     "phoneNumber": event.target.mobileNumber.value,
     "notes":event.target.notes.value,
     "website": event.target.website.value,
     "address": addressRef.current.value,
     "workPhone2":event.target.workPhone1 !== undefined ? event.target.workPhone1.value : null,
     "workPhone3":event.target.workPhone2 !== undefined ? event.target.workPhone2.value : null,
     customPayload: formDataToJson(formData)
   }    
   if(image !== null)
        payload['image'] = image.name

    if(!isEdit()){
    axiosClient.postRequest(constants.COMPANY_URL, payload)
    .then(function (response) {
        if(image !== null){
          axiosClient.putExternalRequest(response.data.link, image,{headers: {
            'Content-Type': 'multipart/form-data',
            'Content-Length': image.size
          }})
          .then(function (response2) {
              spinnerRef.current.style.display = 'none' 
              toast.success('Company Created')
              props.closeCreateWindow(true)
          }).catch((error) => {

          })  
      } else{
        toast.success('Company Updated')
        props.closeCreateWindow(true)
      }
    }).catch((error) => {
        if(error.response.status === 400){
          if(error.response.data.error === '')
            displayError('Error in processing the request')
          else
            displayError(error.response.data.error)
        }else{
          displayError('Error in processing the request')
        }
        spinnerRef.current.style.display = 'none'
    });
  }else{
        axiosClient.patchRequest(constants.COMPANY_URL+props.editIdRef.current, payload)
        .then(function (response) {
            if(image !== null){
              axiosClient.putExternalRequest(response.data.link, image,{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': image.size
              }})
              .then(function (response2) { 
                    toast.success('Company Updated')
                    spinnerRef.current.style.display = 'none'
                    props.closeCreateWindow(true)
              }).catch((error) => {
                  spinnerRef.current.style.display = 'none'
              })  
          } else{
            toast.success('Company Updated')
            spinnerRef.current.style.display = 'none'
            props.closeCreateWindow(true)
          }
        }).catch((error) => {
            if(error.response.status === 400){
              if(error.response.data.error === '')
                displayError('Error in processing the request')
              else
                displayError(error.response.data.error)
            }else{
              displayError('Error in processing the request')
            }
            spinnerRef.current.style.display = 'none'
        });
  }
}



  function addWorkPhoneField(){
    console.log("addNewAddressField "+workPhoneRef.current.children.length)
      if(workPhoneRef.current.children.length >= 3)
        return
      // let div = document.createElement('div')
      let input = document.createElement('input')
      input.style.width = '90%'
      input.style.marginTop = '20px'
      input.name = "workPhone"+workPhoneRef.current.children.length
      input.placeholder = 'Work Phone'
      // div.innerHTML = '<input type="text" name="workPhone'+workPhoneRef.current.children.length+'"  placeholder = "Work Phone" style={{width: "35%",  borderRadius: "5px"}} />'
      workPhoneRef.current.appendChild(input)
      removeWorkPhoneLink.current.style.visibility = 'visible'
      if(workPhoneRef.current.children.length  >= 3)
        addWorkPhoneLink.current.style.visibility='hidden'
  }

  function removeWorkPhoneField(){
         if(workPhoneRef.current.children.length <= 1){
          removeWorkPhoneLink.current.style.visibility = 'hidden'
          return
         }
         workPhoneRef.current.removeChild(workPhoneRef.current.lastChild)
         addWorkPhoneLink.current.style.visibility='visible'
         if(workPhoneRef.current.children.length <= 1)
            removeWorkPhoneLink.current.style.visibility = 'hidden'
  }

  //   function addAddressField(){
  //   console.log("addNewAddressField "+addressRef.current.children.length)
  //     if(addressRef.current.children.length >= 3)
  //       return
  //     let div = document.createElement('div')
  //     div.innerHTML = '<textarea name="address" placeholder="Complete Address" style={{width: "90%"}}/>/>'
  //     addressRef.current.appendChild(div)
  //     removeAddressLink.current.style.display = 'inline'
  //     if(addressRef.current.children.length  >= 3)
  //       addAddressLink.current.style.display='none'
  // }
  // function checkIfEmailExist(event){
  //   console.log('called')
  //   errorRef.current.style.dislay='none';
  //   axiosClient.postRequest(constants.COMPANY_URL+'email_check?email='+event.target.value)
  //   .then(function (response) {
  //     console.log(response.data)
  //     if(response.data.exists === true){
  //       errorRef.current.innerHTML = 'Email already used'
  //       errorRef.current.style.dislay='block';
  //     }
  //   }).catch(function (response) {
  //       console.log(response);
  //   });
  // }
function removeImage(event){
  event.preventDefault()
  document.getElementById("drop-area").style.backgroundImage = null;
  setImage(null)
  uploadImageRef.current.style.display='inline'
  imageRemoveRef.current.style.display='none'
  setImageModified(true)
}  
function uploadCompanyLogo(event){
  event.target.setCustomValidity('')
  console.log("uploadCompanyLogo "+Object.keys(event.target.files).length)
    if(Object.keys(event.target.files).length === 0){
      return
    }
    uploadImageRef.current.style.display='none'
    if(event.target.files[0].size > 2000000){
      event.target.setCustomValidity('Maximum image size is 2MB')
      return
    }
    setImage(event.target.files[0])
    var imgLink = URL.createObjectURL(event.target.files[0])
    document.getElementById("drop-area").style.backgroundImage=`url(${imgLink})`;
    setImageModified(true)
}   

  function removeAddressField(){
         if(addressRef.current.children.length <= 1){
          removeAddressLink.current.style.display = 'none'
          return
         }
         addressRef.current.removeChild(addressRef.current.lastChild)
         addAddressLink.current.style.display='inline'
         if(addressRef.current.children.length <= 1)
            removeAddressLink.current.style.display = 'none'
  }
  function inputClick(event){
    if('focus' === event.type)
      event.target.style.borderColor = 'blue'
    if('blur' === event.type)
      event.target.style.borderColor = 'black'
  }
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}  
  return (
      <div class="create-wrapper">          
        <form onSubmit={handleSubmit}>
          <div style={{padding:'30px', paddingleft:'0px',backgroundColor:'#F5F5F5F5',height: 'calc(100vh - 105px', overflowY: 'scroll'}}>                   
              <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeCreateWindow}>&times;</a>
              <h8 style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}>+ Create Company</h8>
              <div ref={errorRef}/>
              <div class='create-line'></div>
              <div class = 'create-display-image-wrapper'>
                  <div class='drop-area-wrapper'>                          
                    <label for="input-file" id="drop-area" onChange={uploadCompanyLogo}>
                        <div>
                            <img src={require('../../../img/placeholder.png')}  ref={uploadImageRef}/>
                        </div>
                      <input type="file" accept=".png, .jpg, .jpeg" id="input-file" hidden name='image'/>                  
                    </label>              
                  </div>  
                  <div class='create-form-label' style={{marginLeft: '2%', color: '#2e2f32',fontSize:'18px' }}>
                    Upload Logo of the Company
                  </div>  
              </div> 
              <div ref={imageRemoveRef}><a href='' style={{fontSize:'14px',textDecoration:'none',marginLeft:'50px' }} onClick={removeImage}>Remove</a></div>                       
              <div class='create-form-label' style={{ marginTop: "3%"}}>Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="name"  ref={firstNameRef} style={{width: "90%"}} onFocus={inputClick} onBlur={inputClick} placeholder={'Full Name'} required ref={nameRef} maxlength='70'/></div>
              <div style={{ marginTop: "3%"}} class='create-form-label'>Email:</div>
              <div><input type="text" name="email"  style={{width: "90%", outline: 'none' }} onFocus={inputClick} onBlur={inputClick} placeholder={'Email'} ref={emailRef} maxlength='320'/></div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Phone Number:</div>
              <div><input type="text" name="mobileNumber"  style={{width: "90%"}} onFocus={inputClick} onBlur={inputClick} placeholder={'Company Number'} ref={numberRef} maxlength='20'/></div>
              <div style={{marginTop: "3%"}} class='create-form-label'> WorkPhone:
                 <div ref={workPhoneRef}> 
                   <input type="text" name="workPhone"  style={{width: "90%"}} placeholder={'Work Phone'} maxlength='20'/>
                 </div>
              <div>
              <a style={{fontSize: '15px', fontWeight: 'bolder'}} href="#" onClick={addWorkPhoneField} ref={addWorkPhoneLink} >Add Another</a>
              <a style={{fontSize: '15px', fontWeight: 'bolder',marginRight: '10%', float:'right',position:'relative'}}href="#" onClick={removeWorkPhoneField} ref={removeWorkPhoneLink}>Remove </a>
              </div>
              <div style={{marginTop: "3%"}} class='create-form-label'>Website</div>
              <div><input type="text" name="website"  style={{width: "90%"}} placeholder={'Website'} ref={websiteRef} maxlength='500'/> </div>
   
              <div style={{ marginTop: "3%"}} class='create-form-label'>Address:</div>
               <div>
                 <div style={{width: "90%"}}>
                      <textarea name="address"  style={{width: "100%", height: "100px" }} placeholder={'Complete Address'} ref={addressRef}/>
                 </div>
                 <div style={{ marginTop: "3%"}} class='create-form-label'>Notes:</div>              
                 <div style={{width: "90%"}}>
                      <textarea name="notes" style={{width: "100%", height: "100px"}} placeholder={'Some notes'} ref={notesRef}/>
                 </div>
                 <DynamicForm customFields={customFields} setFormData={setFormData} formData={formData} edit={isEdit() }/>
               </div>                
              </div>
            </div>
            <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
              <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Company' : 'Create Company'}</button>
              <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
            </div>              
          </form>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>                      

        </div>       
    );
}

export default CreateCompanyForm;