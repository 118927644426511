import React, { useState, useEffect, useRef } from 'react';
import * as axiosClient from '../../../js/Axios'
import * as constants from '../../../js/constants'
import { ToastContainer, toast } from 'react-toastify';

const actions = {
  "Ticket Management": [
    { name: "Create Ticket", key: "CREATE_TICKET" },
    { name: "View Ticket", key: "VIEW_TICKET" },
    { name: "Change Summary of Ticket", key: "CHANGE_SUMMARY" },
    { name: "Change Description of Ticket", key: "CHANGE_DESCRIPTION" },
    { name: "Change Ticket Status", key: "CHANGE_TICKET_STATUS" },
    { name: "Change Ticket Priority", key: "CHANGE_TICKET_PRIORITY" },
    { name: "Assign the Ticket to an Agent", key: "ASSIGN_TO_AGENT" },
    { name: "Assign Ticket to Self", key: "ASSIGN_TO_SELF" },
    { name: "Escalate Ticket", key: "ESCALATE_TICKET" },
    { name: "Reassign Ticket", key: "REASSIGN_TICKET" },
    { name: "Close Ticket", key: "CLOSE_TICKET" },
    { name: "Reopen Ticket", key: "REOPEN_TICKET" },
    { name: "Merge Tickets", key: "MERGE_TICKETS" },
    { name: "View Ticket History", key: "VIEW_TICKET_HISTORY" },
    { name: "View Ticket Resolution", key: "VIEW_TICKET_RESOLUTION" },
    { name: "Add Ticket Resolution", key: "ADD_TICKET_RESOLUTION" },
    { name: "Edit Others' Resolution", key: "EDIT_RESOLUTION" },
    { name: "Delete Resolution", key: "DELETE_RESOLUTION" },
    { name: "Edit Others' Comments", key: "EDIT_OTHERS_COMMENT" }
  ],
  "Agent Management": [
    { name: "Create Agent", key: "CREATE_AGENT" },
    { name: "View Agents", key: "VIEW_AGENT" },
    { name: "Edit Agent", key: "EDIT_AGENT" },
    { name: "Delete Agent", key: "DELETE_AGENT" },
    { name: "Change Role of Agent", key: "CHANGE_ROLE" },
    { name: "Change Team", key: "CHANGE_TEAM" },
    { name: "Create Team", key: "CREATE_TEAM" },
    { name: "View Team", key: "VIEW_TEAM" },
    { name: "Edit Team", key: "EDIT_TEAM" },
    { name: "Delete Team", key: "DELETE_TEAM" }
  ],
  "Contact Management": [
    { name: "Create Contact", key: "CREATE_CONTACT" },
    { name: "View Contacts", key: "VIEW_CONTACT" },
    { name: "Edit Contact", key: "EDIT_CONTACT" },
    { name: "Delete Contact", key: "DELETE_CONTACT" },
    { name: "Block Contact", key: "BLOCK_CONTACT" },
    { name: "Mark Contact as Spam", key: "MARK_CONTACT_SPAM" },
    { name: "Create Company", key: "CREATE_COMPANY" },
    { name: "View Company", key: "VIEW_COMPANY" },
    { name: "Edit Company", key: "EDIT_COMPANY" },
    { name: "Delete Company", key: "DELETE_COMPANY" },
    { name: "Create Contact Group", key: "CREATE_CONTACT_GROUP" },
    { name: "View Contact Group", key: "VIEW_CONTACT_GROUP" },
    { name: "Edit Contact Group", key: "EDIT_CONTACT_GROUP" },
    { name: "Delete Contact Group", key: "DELETE_CONTACT_GROUP" },
    { name: "Create Company Group", key: "CREATE_COMPANY_GROUP" },
    { name: "View Company Group", key: "VIEW_COMPANY_GROUP" },
    { name: "Edit Company Group", key: "EDIT_COMPANY_GROUP" },
    { name: "Delete Company Group", key: "DELETE_COMPANY_GROUP" }
  ],
    "Call Management": [
    { name: "Make and Receive Calls", key: "DIAL_CALL" },
    { name: "View All Call Logs", key: "VIEW_CALL_LOGS" },
    { name: "View Call Cost", key: "VIEW_CALL_COST" }
  ],
  "Chat Management": [
    { name: "Enable Chat", key: "VIEW_CHAT" },
    { name: "View Chat Bot", key: "VIEW_CHAT" },
    { name: "View Chat Bot", key: "VIEW_CALL_LOGS" },
    { name: "Edit Chat Bot", key: "VIEW_CALL_COST" },
    { name: "Delete Chat Bot ", key: "VIEW_CALL_COST" }
  ],
  "Admin Activities": [
    { name: "Purchase Phone Number", key: "PURCHASE_PHONE_NUMBER" },
    { name: "Release Phone Number", key: "RELEASE_PHONE_NUMBER" },
    { name: "Configure Phone Number", key: "CONFIGURE_PHONE_NUMBER" },
    { name: "View Phone Number", key: "VIEW_PHONE_NUMBER" },
    { name: "Create Call Route", key: "CREATE_CALL_ROUTE" },
    { name: "View Call Route", key: "VIEW_CALL_ROUTE" },
    { name: "Edit Call Route", key: "EDIT_CALL_ROUTE" },
    { name: "Delete Call Route", key: "DELETE_CALL_ROUTE" },
    { name: "Create IVR", key: "CREATE_IVR" },
    { name: "View IVR", key: "VIEW_IVR" },
    { name: "Edit IVR", key: "EDIT_IVR" },
    { name: "Delete IVR", key: "DELETE_IVR" },
    { name: "Create SLA", key: "CREATE_SLA" },
    { name: "View SLA", key: "VIEW_SLA" },
    { name: "Edit SLA", key: "EDIT_SLA" },
    { name: "Delete SLA", key: "DELETE_SLA" },
    { name: "Create Business Hours", key: "CREATE_BUSINESS_HOURS" },
    { name: "View Business Hours", key: "VIEW_BUSINESS_HOURS" },
    { name: "Edit Business Hours", key: "EDIT_BUSINESS_HOURS" },
    { name: "Delete Business Hours", key: "DELETE_BUSINESS_HOURS" },
    { name: "Create Support Email", key: "CREATE_SUPPORT_EMAIL" },
    { name: "View Support Email", key: "VIEW_SUPPORT_EMAIL" },
    { name: "Edit Support Email", key: "EDIT_SUPPORT_EMAIL" },
    { name: "Delete Support Email", key: "DELETE_SUPPORT_EMAIL" },
    { name: "Create Custom Role", key: "CREATE_CUSTOM_ROLE" },
    { name: "View Custom Role", key: "VIEW_CUSTOM_ROLE" },
    { name: "Edit Custom Role", key: "EDIT_CUSTOM_ROLE" },
    { name: "Delete Custom Role", key: "DELETE_CUSTOM_ROLE" },
    { name: "Create Custom Role", key: "CREATE_CUSTOM_FIELD" },
    { name: "View Custom Role", key: "VIEW_CUSTOM_FIELD" },
    { name: "Edit Custom Role", key: "EDIT_CUSTOM_FIELD" },
    { name: "Delete Custom Role", key: "DELETE_CUSTOM_FIELD" },
    { name: "View Subscription Details", key: "VIEW_SUBSCRIPTION_DETAILS" },
    { name: "Cancel Subscription", key: "CANCEL_SUBSCRIPTION" },
    { name: "Add Call Credits", key: "ADD_CALL_CREDITS" },
    { name: "Add/Remove Licenses", key: "ADD_REMOVE_LICENSES" },
    { name: "Change Shipping Address", key: "CHANGE_SHIPPING_ADDRESS" },
    { name: "Change Billing Address", key: "CHANGE_BILLING_ADDRESS" },
    { name: "Renew Account", key: "RENEW_ACCOUNT" },
    { name: "View Chatbot", key: "VUEW_CHAT_BOT" }
  ],
  "KB Activities": [
    { name: "View Knowledge Base", key: "VIEW_KB" },
    { name: "Create Category", key: "CREATE_CATEGORY" },
    { name: "Edit Category", key: "EDIT_CATEGORY" },
    { name: "Delete Category", key: "DELETE_CATEGORY" },
    { name: "Create Article", key: "CREATE_ARTICLE" },
    { name: "Edit Article", key: "EDIT_ARTICLE" },
    { name: "Publish Article", key: "PUBLISH_ARTICLE" },
    { name: "Unpublish Article", key: "UNPUBLISH_ARTICLE" },
    { name: "Delete Article", key: "DELETE_ARTICLE" }
   ],  
}    
  

const CreateCustomRole = (props) => {
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const errorRef = useRef(null)
  const spinnerRef = useRef(null)
  const nameRef = useRef(null)
  const descriptionRef = useRef(null)
  let editFilled = false;

  useEffect(() => {   
      errorRef.current.style.display = 'none'
      spinnerRef.current.style.display = 'none'         
      if(isEdit() && !editFilled){
        editFilled = true;
        fillForm(); 
      }  
  },[])

  function fillForm(){
    console.log('fillForm')
      axiosClient.getRequest(constants.CUSTOM_ROLE_URL+props.editIdRef.current)
          .then(function (response) {
            console.log('permissions '+JSON.stringify(response.data.permissions))
                nameRef.current.value = response.data.name      
                descriptionRef.current.value = ((response.data.description === 'null') ? '' : response.data.description)
                if(response.data.permissions.split(";").length !== 0)
                  setSelectedPermissions(response.data.permissions.split(";"))
          }).catch((error) => {
            console.log(error)
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  displayError('Error in processing the request')
                else
                  displayError(error.response.data.error)
              }else{
                displayError('Error in processing the request')
              }
              spinnerRef.current.style.display = 'none'
          });
  }


  function isEdit(){
    return props.editIdRef.current !== undefined && props.editIdRef.current !== -1
  }

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()

}
  const handleSubmit = (e) => {
    e.preventDefault();
    let payload = {name: e.target.name.value,
           description: descriptionRef.current.value,
           permissions: selectedPermissions
    }
    console.log("Selected Permissions: ", JSON.stringify(selectedPermissions));
    if(!isEdit()){
        console.log('sending post request')
          axiosClient.postRequest(constants.CUSTOM_ROLE_URL, payload)
          .then(function (response) {      
            props.closeCreateWindow(true)
          }).catch((error) => {
            console.log(error)
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  displayError('Error in processing the request')
                else
                  displayError(error.response.data.error)
              }else{
                displayError('Error in processing the request')
              }
              spinnerRef.current.style.display = 'none'
          });
    }else{
      console.log('sending patch request' + JSON.stringify(payload))
      axiosClient.patchRequest(constants.CUSTOM_ROLE_URL+props.editIdRef.current, payload)
          .then(function (response) {      
                  toast.success('Custom Role Updated')          
                  props.closeCreateWindow(true)
                  spinnerRef.current.style.display = 'none'
          }).catch((error) => {
            console.log(error)
              if(error.response.status === 400){
                if(error.response.data.error === '')
                  displayError('Error in processing the request')
                else
                  displayError(error.response.data.error)
              }else{
                displayError('Error in processing the request')
              }
              spinnerRef.current.style.display = 'none'
          });
    }
  };

const handleCategoryCheckboxChange = (category, isChecked) => {
  console.log('handleCategoryCheckboxChange  '+JSON.stringify(selectedPermissions))
  const categoryActions = actions[category].map(action => action.key);

  if (isChecked) {
    // Add all actions in the category to selectedPermissions without affecting other categories
    setSelectedPermissions((prevPermissions) =>
      [...new Set([...prevPermissions, ...categoryActions])] // Ensure no duplicates
    );
  } else {
    // Remove all actions in the category from selectedPermissions
    setSelectedPermissions((prevPermissions) =>
      prevPermissions.filter((perm) => !categoryActions.includes(perm))
    );
  }
};

const handleCheckboxChange = (key) => {
  setSelectedPermissions((prevPermissions) => {
    if (prevPermissions.includes(key)) {
      // Remove the permission if already selected
      return prevPermissions.filter(permission => permission !== key);
    } else {
      // Add the permission if not selected
      return [...prevPermissions, key];
    }
  });
};

  return (

  <div class="create-wrapper">      
      <form onSubmit={handleSubmit}>          
          <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
              <a href="javascript:void(0)" class="create-closebtn" onClick={props.closeCreateWindow}>&times;</a>
              <div style={{color:'#044caf',fontWeight: '800', fontSize:'20px'}}>+ Create Role</div>
              <div ref={errorRef}  class='create-error'/>              
              <div class='create-line'></div> 
              <div class='create-form-label' style={{ marginTop: "3%"}}>Name:<p class='red-color'>*</p></div>
              <div><input type="text" name="name" style={{width: "90%"}} placeholder={'Name Of the Role'} required ref={nameRef} maxlength='500'/></div>
              <div class='create-form-label' style={{ marginTop: "3%"}}>Description:</div>
              <div><input type="text" name="description" style={{width: "90%"}} placeholder={'Description .. '}  ref={descriptionRef} maxlength='500'/></div>              
              <h3 style={{marginTop:'20px'}}> Permissions </h3>
              {Object.keys(actions).map((category) => {
                    // Check if all actions in the category are selected
                    const allSelected = actions[category].every(action => selectedPermissions.includes(action.key));
                    
                    // Check if some actions are selected (for a partial selection UI)
                    const someSelected = actions[category].some(action => selectedPermissions.includes(action.key));

                    return (
                      <div key={category} style={{marginTop:'20px'}}>
                        <h5>                         
                            <input
                              type="checkbox"
                              checked={allSelected}
                              indeterminate={someSelected && !allSelected} // Use indeterminate state for partial selections
                              onChange={(e) => {
                                const isChecked = e.target.checked;
                                handleCategoryCheckboxChange(category, isChecked);
                              }}
                            />
                            <span style={{marginLeft:'5px'}}>  {category} </span>
                        </h5>
                        {actions[category].map((action) => (
                          <div key={action.key} style={{marginLeft:'25px', marginTop:'5px'}}>                            
                              <input
                                type="checkbox"
                                checked={selectedPermissions.includes(action.key)} // Pre-check based on currentPermissions state
                                onChange={() => handleCheckboxChange(action.key)}                                
                              />
                              <span style={{marginLeft:'5px',fontWeight:'600', fontSize:'16px', fontFamily:'Lato'}}> {action.name} </span>                            
                          </div>
                        ))}
                      </div>
                    );
                  })}


          </div>
          <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
              <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}}>{isEdit() ? 'Update Role' : 'Create Role'}</button>
              <span class="spinner-border" role="status" style={{position:'relative',top:'10px',right:'75px', color: 'black', height:'25px', width:'25px',zIndex:'100'}} ref={spinnerRef}/>
          </div>
        </form>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>        
    </div>       

  );
};

export default CreateCustomRole;