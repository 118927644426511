import React, { useState, useCallback , useEffect, useRef} from 'react';
import NodePanel from './NodePanel'
import ReactFlow, {applyNodeChanges, applyEdgeChanges, addEdge, 
  useNodesState,useEdgesState,MarkerType,Background,Controls }from 'react-flow-renderer';
import ChatMenuBox from './ChatMenuBox'
import * as constants from '../../../js/constants';
import * as axiosClient from '../../../js/Axios';
import CreateChatBotWindow from './CreateChatBotWindow'
import { ToastContainer, toast } from 'react-toastify';
import { useParams} from "react-router-dom"
import DummyNode from './DummyNode'


const chatMenuBoxWidth = 200; // Assume the width of chatMenuBox
const chatMenuBoxHeight = 100; // Assume the height of chatMenuBox
const dummyNodeHeight = 40; // Assume the height of dummyNode
const gap = 50; // Spacing between nodes

const initialNodes = [
  { id: '1', type: 'chatMenuBox', position: { x: 0, y: 0 },  data: {name: 'Start',message: 'Initial Node', type:'welcome_message'}},
  { id: '2', type: 'dummyNode', position: { x: chatMenuBoxWidth + gap, y: (chatMenuBoxHeight - dummyNodeHeight) / 2 } ,  data: {name: 'Start',message: 'Initial Node', type:'message'} }
];


const nodeTypes = {
  chatMenuBox: ChatMenuBox, // Register your custom node
  dummyNode:DummyNode
};
const CreateChatBotForm = () => {
  const [nodes, setNodes] = useNodesState(initialNodes);
  const [edges, setEdges] = useEdgesState([{ id: 'e1-2', source: '1', target: '2', type: 'smoothstep' }]);
  const [edge, setEdge] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  const [displayNodePanel, setDisplayNodePanel] = useState(false)
  const [create, setCreate] = useState(false)
  const [name, setName] = useState(null)
  const [description, setDescription] = useState(null)
  const params = useParams();
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const contextMenuRef = useRef(null);
  const [selectedEdge, setSelectedEdge] = useState(null);
  const fetchCalled = useRef(false);
  
  useEffect(() => {
    if (params.id && !fetchCalled.current) {
        fetchCalled.current = true; // Mark as called

        axiosClient.getRequest(`${constants.CHAT_BOT_URL}/${params.id}`)
            .then(response => {
                const updatedNodes = response.data.nodes.map((node) => ({
                    ...node,
                    data: {
                        ...node.data,
                        handleDelete,
                        // handleTargetHandleDoubleClick,
                        id: node.id
                    }
                }));

                setNodes(updatedNodes);
                setEdges(response.data.edges);
                setName(response.data.name);
        })
        .catch((error) => {
            console.error("Error fetching chatbot data:", error);
        });
    }
}, [params.id]); 


const onEdgesChange = (changes) => {
  setEdges((prevEdges) => applyEdgeChanges(changes, prevEdges));
};


const onConnect = (connection) => {
  setEdges((prevEdges) => [
    ...prevEdges,
    {
      ...connection,
      type: "straight", // Ensures edges are straight
      style: { stroke: "rgb(4, 76, 175)", strokeWidth: 1, strokeDasharray: "0" , cursor: "pointer"}, // Solid line
      markerEnd: { type: MarkerType.ArrowClosed }, // Adds arrow at the end
    },
  ]);
};

const handleDelete = (event, data) => {
    event.stopPropagation();

    let updatedEdges;
    setEdges((prevEdges) => {
        const outgoingEdges = prevEdges.filter(edge => edge.source === data.id && edge.sourceHandle !== 'notSatisfied'); // Edges from deleted node
        const incomingEdges = prevEdges.filter(edge => edge.target === data.id); // Edges to deleted node

        // Get the target and source node IDs
        const targetNodeIds = outgoingEdges.map(edge => edge.target); // Nodes connected after the deleted node
        const sourceNodeIds = incomingEdges.map(edge => edge.source); // Nodes connected before the deleted node

        // Remove edges related to the deleted node
        updatedEdges = prevEdges.filter(edge => edge.source !== data.id && edge.target !== data.id);

        // Reconnect all target nodes to all source nodes
        sourceNodeIds.forEach(sourceId => {
            targetNodeIds.forEach(targetId => {
                updatedEdges.push({
                    id: `node-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
                    source: sourceId,
                    target: targetId,
                    type: 'smoothstep'
                });
            });
        });

        return updatedEdges;
    });
    setNodes((prevNodes) => {
        // const updatedEdges = edges; // Get latest edges (ensuring sync execution)
        const connectedNodeIds = new Set(updatedEdges.flatMap(edge => [edge.source, edge.target])); // Nodes with edges

        return prevNodes.filter(node => connectedNodeIds.has(node.id)); // Keep only connected nodes
    });

};


function addNode(selectedNode) {
  let newNodeId, newDummyId1, newDummyId2;
setNodes((prevNodes) => {
    newNodeId = `node-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    newDummyId1 = `node-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
    newDummyId2 = `node-${Date.now()}-${Math.floor(Math.random() * 1000)}`;

    // Find the source edge (incoming edge to selectedNode)
    const sourceEdge = edges.find(edge => edge.target === selectedNode.id);
    if (!sourceEdge) return prevNodes; // If no source edge, return as is

    const sourceNode = prevNodes.find(node => node.id === sourceEdge.source);
    if (!sourceNode) return prevNodes;

    // Position logic based on sourceHandle
    const isNotSatisfied = sourceEdge.sourceHandle === 'notSatisfied';
    const newPosition = isNotSatisfied
        ? { x: sourceNode.position.x+200, y: sourceNode.position.y + 300 } // Place below
        : { x: sourceNode.position.x + 250, y: sourceNode.position.y }; // Place to the right

    // Create the new node
    const newNode = {
        id: newNodeId,
        type: selectedNode.type, 
        position: newPosition,
        data: { ...selectedNode.data, handleDelete, id: newNodeId }
    };
    let updatedNodes;

console.log('addNode')
    // Create the first dummy node (to the right)
    if(selectedNode.data.type !== 'forward_to_operator' && selectedNode.data.type !== 'terminate_chat'){
      const newDummyNode1 = {
          id: newDummyId1,
          type: 'dummyNode',
          position: { x: newPosition.x + 250, y: newPosition.y },
          data: { name: 'Add Node', message: '', type: 'dummy_node' }
      };

      updatedNodes = prevNodes.filter(node => node.id !== selectedNode.id).concat(newNode, newDummyNode1);
    }else{
      updatedNodes = prevNodes.filter(node => node.id !== selectedNode.id).concat(newNode);
    }

    // If it's a decision tree, add the second dummy node (below)
    if (selectedNode.data.type === 'decision_tree') {
        const newDummyNode2 = {
            id: newDummyId2,
            type: 'dummyNode',
            position: { x: newPosition.x + 100, y: newPosition.y + 300 }, 
            data: { name: 'Add Node', message: '', type: 'dummy_node' }
        };

        updatedNodes = updatedNodes.concat(newDummyNode2);
    }

    return updatedNodes;
});


    setEdges((prevEdges) => {
        console.log('inside set edges');

        const updatedEdges = prevEdges.map(edge => 
            edge.target === selectedNode.id
                ? { 
                    ...edge, 
                    target: newNodeId, 
                    sourceHandle: edge.sourceHandle // Retain sourceHandle if it exists
                }
                : edge
        );

        const newEdges = [
            {
                id: `edge-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
                source: newNodeId,
                target: newDummyId1,
                type: 'smoothstep'
            }
        ];

        if (selectedNode.data.type === 'decision_tree') {
            newEdges.push({
                id: `edge-${Date.now()}-${Math.floor(Math.random() * 1000)}`,
                source: newNodeId,
                target: newDummyId2,
                type: 'smoothstep',
                sourceHandle: 'notSatisfied'
            });
        }

        return [...updatedEdges, ...newEdges];
    });

}



   // Function to handle node click and open panel
  const handleNodeClick = useCallback((event, node) => {
    event.preventDefault()
    console.log('handleNodeClick   ' +node.type)
    if(node.data?.type === 'welcome_message')
      return
    const newNode = nodes.find(n => n.id === node.id);
    setSelectedNode(newNode);     
    setDisplayNodePanel(true)
  }, [nodes])



  const updateNode = (updatedNode) => {
    console.log('updateNode '+JSON.stringify(nodes))
    setNodes((nds) =>
      nds.map((n) => (n.id === updatedNode.id ? updatedNode : n))
    );
    setSelectedNode(null); // Close the panel after updating
  };

const onNodesChange = (changes) => {
  setNodes((prev) => applyNodeChanges(changes, prev));
};

const onEdgeDoubleClick = useCallback((event, edge) => {
    event.preventDefault();    
    setEdges((prevEdges) => prevEdges.filter((e) => e.source !== edge.source || e.target !== edge.target));
}, []);





 const onMouseMove = useCallback((event) => {
    if (!edge) return;
    setEdge((prev) => ({
      ...prev,
      targetX: event.clientX,
      targetY: event.clientY,
    }));
  }, [edge]);



const validateBotFlow = (nodes, edges) => {
      const nodesWithOutgoingEdges = new Set(edges.map(edge => edge.source));
      const endNodes = nodes.filter(node => !nodesWithOutgoingEdges.has(node.id));
      const invalidEndNodes = endNodes.filter(node => node.type !== "terminate_node");
      if (invalidEndNodes.length > 0) {

      const updatedNodes = nodes.map(node => ({
          ...node,
          data: {
              ...node.data,
              highlight: invalidEndNodes.some(invalidNode => invalidNode.id === node.id)
          }
      }));

      setNodes([...updatedNodes]); 

        return false
    }
    return true; // Flow is valid
};

function createBot(name, description){
  console.log('createBot '+JSON.stringify(nodes)+' and    '+JSON.stringify(edges))
  // if(!validateBotFlow(nodes,edges)){
  //   toast.error("Every flow must end with termination node type")
  //   return
  // }
  let payload = {name:name, description: description,
                    nodes: nodes,
                edges: edges
              }
  if(params.id === undefined){
      axiosClient.postRequest(constants.CHAT_BOT_URL, payload)
         .then(function (response) {
            toast.success("Bot Created Successfully")   
            window.location.href='/admin/bot' 
         }).catch((error) => {
             console.log(error?.response?.data?.error)
             if(error?.response?.data?.error){
              toast.error(error?.response?.data?.error)
             }else{
              toast.error('Unexpected error occured.... Please contact support')
             }
         });
  }else{

      axiosClient.patchRequest(constants.CHAT_BOT_URL+'/'+params.id, payload)
         .then(function (response) {
            toast.success("Bot Updated Successfully")     
         }).catch((error) => {
             console.log(error?.response?.data?.error)
             if(error?.response?.data?.error){
              toast.error(error?.response?.data?.error)
             }else{
              toast.error('Unexpected error occured.... Please contact support')
             }
         });
  }
}

function isEdit(){
  return params.id !== undefined
}
function closeCreateWindow(){
  setSelectedNode(null)
  setDisplayNodePanel(false)
} 

function cancelBot(){
  window.location.href='/admin/bot'
}



  return (

<>
      <div class='header-body'>

          <div class="main-header" style={{justifyContent:'end'}}>

                {/*<button class='ss-button' style={{float:'right'}} onClick={()=>setDisplayNodePanel(true)}>Add Node</button> */}
                <button class='ss-button' style={{float:'right'}} onClick={()=>setCreate(true)}>Save</button>
                 <button class='ss-button' style={{float:'right'}} onClick={cancelBot}>Cancel</button>
          </div>
      </div>
      <div class='main-body' style={{height:'100%', width:'100%',paddingLeft:'10px'}}>
        {displayNodePanel && (<NodePanel closeCreateWindow={closeCreateWindow} addNode={addNode} sbOptions={[]}
          updateNode={updateNode}  selectedNode={selectedNode}/>)}
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            nodeTypes={nodeTypes} 
            onNodeClick={handleNodeClick}
            defaultEdgeOptions={{ style: { cursor: "pointer" } }}
            onEdgeDoubleClick={onEdgeDoubleClick}
          >
          <Background />
          <Controls />    
          </ReactFlow>          
         {create ?  <CreateChatBotWindow name={name} description={description} createBot={createBot} setCreate={setCreate}/> : ''}
      </div>
      <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                    newestOnTop={false} closeOnClick rtl={false} 
                    draggable pauseOnHover theme="colored"/>  
</>
  );
};

export default CreateChatBotForm;





