import React, { useState, useEffect, useRef,createContext } from 'react';
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import PhoneNumberLine from "./PhoneNumberLine"
import PhoneNumberListDisplayHeader from './PhoneNumberListDisplayHeader'
import PurchasePhoneNumber from './PurchasePhoneNumber'
import ConfigurePhoneNumber from './ConfigurePhoneNumber'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import Table from 'react-bootstrap/Table';
import { usePermissions } from '../../../PermissionsProvider';

function PhoneNumberListDisply(){
	
	const primaryCheckBoxRef = useRef(null)
	const groupButtonsRef = useRef(null)
	const [ids, setIds] = useState([])
	const [showConfigrePhoneNumberForm, setShowConfigurePhoneNumberForm] = useState(false)
	const releasePhoneNumberModalRef = useRef(null)
	const releasePhoneNumberRef = useRef(null)
	const [phoneNumberLine, setPhoneNumberLine] = useState("No Records Found...")
	const [checkboxState, setCheckboxState] = useState(false)
	const [showCreatePhoneNumberForm, setShowCreatePhoneNumberForm] = useState(false)
	const phoneNumberIdRef = useRef(-1);

	useEffect(() => {
		releasePhoneNumberModalRef.current.style.display='none'
		loadNumbers();
		if(window.location.href.includes('purchase'))
		{
			setShowCreatePhoneNumberForm(true)
		}
	},[])//use effect

function loadNumbers(){
		axiosClient.getRequest(constants.ACCOUNT_URL+'phone_number')
		.then(function (response) {
				let phoneNumberList = response.data.map((element, index) => (				
								<PhoneNumberLine phoneNumber= {element}
								openConfigurePhoneNumberForm={openConfigurePhoneNumberForm}
								releasePhoneNumber={releasePhoneNumber}/>
				));
				setPhoneNumberLine(phoneNumberList) 
				}).catch(function (response) {
				    console.log(response);
				});	
}
function selectMultiplePhoneNumbers(){
	let elements = document.getElementsByClassName('phoneNumber-checkbox')
	for (var i = 0; i < elements.length; i++) {
		elements[i].style.visibility = 'visible'
	}
}

function searchPhoneNumbers(event){
	console.log("searchPhoneNumbers "+event.target.value)
	axiosClient.getRequest(constants.CONTACT_URL+"filter?keyword="+event.target.value)
		.then(function (response) {
		let phoneNumberList = response.data.map((element, index) => (					
						<PhoneNumberLine phoneNumber= {element} 
						openConfigurePhoneNumberForm={openConfigurePhoneNumberForm}
						releasePhoneNumber={releasePhoneNumber}/>					           
		));
		console.log("PhoneNumberList "+ JSON.stringify(phoneNumberList))
		setPhoneNumberLine(phoneNumberList) 
		}).catch(function (response) {
		    console.log(response);
		});
}


function handleSubmit(event){
  event.preventDefault()

}
function closePhoneWindow(){
	setShowCreatePhoneNumberForm(false)
}
function setShowPurchasePhoneNumberFormFun(){
	setShowCreatePhoneNumberForm(true)
}
function openConfigurePhoneNumberForm(id){
	console.log("openConfigurePhoneNumberForm "+id)
	phoneNumberIdRef.current = id
	setShowConfigurePhoneNumberForm(true)
}

function closeConfigurePhoneNumberWindow(){
	setShowConfigurePhoneNumberForm(false)
}
function releasePhoneNumber(event, phoneNumber){
	releasePhoneNumberRef.current = phoneNumber
	releasePhoneNumberModalRef.current.style.display='block'
}

function cancelModalbox(){
	releasePhoneNumberModalRef.current.style.display='none'	
}
function releasePhoneNumberConfirm(){
	releasePhoneNumberModalRef.current.style.display='none'
	let payload = {phoneNumber : releasePhoneNumberRef.current}
	axiosClient.postRequest(constants.TWILIO_URL+'release',payload)
	.then(function (response) {				
		toast.success('Phone Number Released... ')									
	}).catch(function (error) {
		console.log(error)
		toast.error('Unable to process the request....  ')
	    // displayError(error.response.data.error)
	});	
}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('contact-line-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value+' '+ids)
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.visibility = 'visible'
	}else{
		groupButtonsRef.current.style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
	console.log('checkBoxClicked '+ idsTemp)
}

function performAction(type){	
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.ACCOUNT_URL+'phone_number/delete'

	//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {

       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden'     
}
	return (
		  <>
			<PhoneNumberListDisplayHeader setShowPurchasePhoneNumberFormFun = {setShowPurchasePhoneNumberFormFun} 
										  groupButtonsRef={groupButtonsRef}
									  	  performAction={performAction}/>
			<div class="main-body" style={{backgroundColor: 'white'}}>
					<div class="container" style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
			         <div class="row">
			            <div class="col-lg-12">
			               <div class>
			                  <div class="table-responsive">
			                     <table class="table table-nowrap align-middle table-borderless">
			                        <thead>
			                           <tr>			                             
			                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Number</th>
			                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Type</th>
			                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Country</th>
			                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Is Active</th>
			                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Next Renewal Date</th>
										  <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}></th>                              
			                           </tr>
			                        </thead>
			                        <tbody>
			                        	{phoneNumberLine}                    
			                        </tbody>
			                     </table>
			                  </div>
			               </div>
			            </div>
			         </div>         
			      </div>
					{ showCreatePhoneNumberForm ? <PurchasePhoneNumber closePhoneWindow={closePhoneWindow}/> : ''}
					{ showConfigrePhoneNumberForm ? <ConfigurePhoneNumber closeConfigurePhoneNumberWindow={closeConfigurePhoneNumberWindow}
													phoneNumberIdRef = {phoneNumberIdRef}/> : '' }
		</div>

				<div  ref={releasePhoneNumberModalRef} className="modal show" style={{ position: 'initial' }}>
				      <Modal.Dialog>
				        <Modal.Header >
				          <Modal.Title>Release Number</Modal.Title>
				        </Modal.Header>

				        <Modal.Body>
				          <p style={{fontWeight: '600' }}>Please confirm to Release the Number.</p>
				        </Modal.Body>

				        <Modal.Footer>
				          <Button variant="secondary" onClick={cancelModalbox}>Cancel</Button>
				          <Button variant="primary" onClick={releasePhoneNumberConfirm}>Confirm</Button>
				        </Modal.Footer>
				      </Modal.Dialog> 
				</div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>				
		  </>
 );

}
export default PhoneNumberListDisply;
