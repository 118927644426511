import { CognitoUserPool, CognitoUserAttribute, CognitoUser, AuthenticationDetails, CognitoIdentityCredentials, WebIdentityCredentials } from 'amazon-cognito-identity-js';
import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate, Redirect} from "react-router-dom"
import * as axiosClient from '../../js/Axios';
import * as awsProfile from '../../js/awsProfile';
import * as constants from '../../js/constants';
import AccountCheck from '../AccountCheck'
import './login.css'

function LoginComponent(props){
  const toastRef=useRef(null)
  const navigate = useNavigate();
  const spinnerRef = useRef(null)
  const errorRef = useRef(null)
  const buttonRef = useRef(null)

  if(!window.location.href.includes('/login')){
    console.log('LoginComponent Before login')
    localStorage.removeItem('id_token')
    window.location.href='/login'
  }

  useEffect(() => {
    console.log("Login ")
    toastRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'none'
    // errorRef.current.style.display = 'none'
  },[])  

function displayToast(msg,hide){
  toastRef.current.innerHTML = msg
  toastRef.current.style.display='flex'
}

function setLastSeenCookie() {
  const d = new Date();
  let cname = 'lastseen'
  let cvalue = d.getTime();  
  d.setTime(d.getTime() + (8*60*60*1000));
  let expires = "expires="+ d.toUTCString();
  document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}

  function handleSubmit(event){
    event.preventDefault();
    buttonRef.current.disabled = true
    errorRef.current.style.display = 'none'
    spinnerRef.current.style.display = 'block'
    spinnerRef.current.style.display = 'inline-block'
    let payload = {'email' : event.target.email.value,
            'password' : event.target.password.value
          }
    const authenticationDetails = new AuthenticationDetails({
       Username: event.target.email.value,
       Password: event.target.password.value
    })  

      const userData = {
       Username: event.target.email.value,
       Pool: awsProfile.userPool
      }
    const cognitoUser = new CognitoUser(userData)
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: function (result) {
              localStorage.setItem('id_token' , result.idToken.jwtToken)
              localStorage.setItem('access_token' , result.accessToken.jwtToken)
              localStorage.setItem('refresh_token', result.refreshToken.token)
              var future = new Date();
              future.setDate(future.getDate() + 30);        
              localStorage.setItem('refresh_token_expiry', future.getTime())        
              axiosClient.postRequest(constants.LOGIN_URL)
              .then(function (response) {
                  setLastSeenCookie();
                  window.location.href='/'
                  spinnerRef.current.style.display = 'none'           
               }).catch(function (error) {
                  console.log('login reached error ')
                  buttonRef.current.disabled = false
                  if(error.response !== undefined && error.response.data !== undefined && 
                      error.response.data.message === 'INACTIVE_EMAIL'){
                        localStorage.removeItem('id_token')
                        localStorage.removeItem('access_token')
                        localStorage.removeItem('refresh_token')
                        localStorage.removeItem('refresh_token_expiry')
                        displayError('Email not verified...')
                        spinnerRef.current.style.display = 'none'
                        return 
                  }
                  console.log('error '+ (error.status))
                  if(error === 'AxiosError: Network Error')
                    displayError(error.message)
                  else//TODO correct his actual error is coming a network error
                    displayError('Invalid email id')
          }); 
          spinnerRef.current.style.display = 'none'             
          
        },         
          onFailure: function(err) {
            displayError(err.message)
            spinnerRef.current.style.display = 'none'
            buttonRef.current.disabled = false            
          }     
       });    
  }

  function redirectSignup(){
    console.log('redirectSignup')
    window.location.href='/signup'
  }

  return(
 
<div class="login-wrapper quadrant-left quadran-right" >

   <div class="inside-wrapper" >
      <div class="logo"> <img src={require('../../img/logo.png')} /></div>
      <div class="form-container" style={{marginTop:'30px' }}>
         <div class="slide-controls">
            <input type="radio" name="slide" id="login" checked/>
            <input type="radio" name="slide" id="signup" onChange={redirectSignup}/>
            <label for="login" class="slide login">Login</label>
            <label for="signup" class="slide signup" >Signup</label>
            <div class="slider-tab"></div>
         </div>
         <div class="form-inner">
            <form id="login" onSubmit={handleSubmit} class="login">
               <div class="field">
                  <input type="text" placeholder="Email" name='email' required style={{padding:'5px',height:'33px',fontSize:'18px'}}/>
               </div>
               <div class="field" >
                  <input type="password" placeholder="Password" name='password' required style={{padding:'5px',height:'33px',fontSize:'22px'}}/>
               </div>
               <div class="rest-link" style={{marginBottom:'30px',marginTop:'30px',fontSize:'16px' }}><a href="/forgot_password">Forgot password?</a></div>
               <div style={{display:'table',margin:'auto',width:'100%'}}>
                <button type="submit" ref={buttonRef} class='ss-button' style={{borderRadius: '10px',padding:'10px 20px',width:'100%',fontSize:'17px' }}>Login</button>
               </div>
               <span class="spinner-border" role="status" style={{position:'relative', left:'145px',bottom:'45px', color: 'white', height:'35px', width:'35px',zIndex:'100'}} ref={spinnerRef}/>
               <div class="signup-link" style={{fontSize:'16px'}}>Not a member? <a href="/signup" >Signup now</a></div>
            </form>             
         </div>
      </div>
      <div ref={errorRef} style={{marginTop:'10px',fontSize:'16px'}}/>
   </div>
   <div class='toaster' ref={toastRef}/>
   <AccountCheck />
</div>
)}

export default LoginComponent