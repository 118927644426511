import React, { useState, useEffect, useRef,useContext } from 'react';
import {useNavigate} from "react-router-dom"
import * as constants from '../../../js/constants'


import botImg from '../../../img/bot.png';

function BotLine(props){
 
 const navigate = useNavigate(); 
 const [sessionData, setSessionData] = useState(null)
  

		function editBot(){
				props.editBot(props.bot.id)
		}
		function checkBoxClicked(event){
			props.checkBoxClicked(props.bot.id, event.target.checked)
		}

	const formatDate = (dateString) => new Date(dateString).toLocaleDateString();
	let marginTopIndex = (props.index+3)+'px'


  return (
				<div className='bot-card'>

				      <div className="bot-header">
				        <input
				          type="checkbox"
				          onChange={checkBoxClicked}
				          aria-label={`Select bot ${props.bot.name}`}
				        />
				        
				      </div>

				      <div className="bot-avatar">
				        <img src={botImg} alt={props.bot.name || "Bot Avatar"} />
				      </div>

				      <div className="bot-info">
				        <a className="bot-name" onClick={editBot}>🤖 {props.bot.name}</a>
				        <p className="bot-meta">
				          Created {formatDate(props.bot.createdDate)} • Updated {formatDate(props.bot.modifiedDate)}
				        </p>
				        <p className="bot-description">{props.bot.description || "No description available"}</p>

				      </div>
				      <button className={`chatbot-status-btn ${props.bot.status === 'Draft' ? 'active' : 'inactive'}`}>
					      	{props.bot.status || "Unknown"}
					  </button>

							       {/*
				        <div className="bot-stats">
				          <div className="stat">
				            <p className="stat-title">Chats</p>
				            <p className="stat-value">{props.bot.chats}</p>
				          </div>
				          <div className="stat">
				            <p className="stat-title">Finished</p>
				            <p className="stat-value">{props.bot.finished}</p>
				          </div>
				        </div>

				        <div className="bot-actions">
				          <button className="btn">📊 Stats</button>
				          <button className="btn">💬 Messages</button>
				          <button className="btn">🔗 Open</button>
				          <button className="btn">⋮</button>
				        </div>
				        */}
				</div>

				       
   );

}

export default BotLine;