// import '../../css/CreateChat.css'
import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import { Handle, Position } from 'react-flow-renderer';
import '../css/CreateChat.css'

function ChatMenuBox({data }){
  function getChatMenuHeaderStyle(type) {
      if( ["button","single_choice" ,"multiple_choice","date_selection","date_time_selection",
          "slider","star_rating", "upload_file"].includes(type))
        return '#006fd2';
      else if(["response_message","response_article", "response_video","response_link"].includes(type))
        return 'rebeccapurple';
      else if(["forward_to_operator","set_user_property","trigger_api","create_ticket","resolve_ticket","reopen_ticket"].includes(type))
        return '#FFD700';
      else if(["block_visitor","terminate_chat","restart_chat","final_message"].includes(type))
        return 'black'
      else
        return 'rgb(91, 88, 246)';  
  }

	return(
		<div  className={`chat-menu-card ${data.highlight ? "node-highlight" : ""}`}>
        <div class="chat-menu-header"  style={{backgroundColor: getChatMenuHeaderStyle(data.type), borderColor:'rebeccapurple'}}> 
            <div class="create-chat-editable-label">
                <p id="labelText">{data.name}</p>
            </div>
            
          {data.type !== 'welcome_message' &&  (<div class="create-chat-delete-icon" onClick={(e)=>data.handleDelete(e, data)}></div>)}
        </div>
        
        <div class="chat-menu-content">
            {data.message}
        </div>
{/* 
        {data.type === 'decision_tree' ? (
            <>
              <Handle
                type="source"
                position={Position.Right}
                id="satisfied"
                style={{ background: '#555' }}
              />
              <span className="handle-label right-label">Yes</span>

              <Handle
                type="source"
                position={Position.Bottom}
                id="notSatisfied"
                style={{ background: '#555' }}
              />
              <span className="handle-label bottom-label">No</span>
            </>
          ) : (
            <Handle
              type="source"
              position={Position.Right}
              style={{ background: '#555' }}
            />
      )}  
*/}


        {
          data.type === 'decision_tree' ? (
            <>
              <Handle type="source" position={Position.Right} id="satisfied" style={{ background: '#555' }} />
              <Handle type="source" position={Position.Bottom} id="notSatisfied" style={{ background: '#555' }} />
            </>
          ) : data.type === 'forward_to_operator' ? null : (
            <Handle type="source" position={Position.Right} style={{ background: '#555' }} />
          )
        }        
        <Handle
          type="target"
          position={Position.Left}
          style={{ background: '#555' }}
        /> 
           
    </div>
     
		)
}



export default ChatMenuBox;