import React, { useState, useEffect, useRef } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import './sla.css'
import SLALine from "./SLALine"
import CreateSLAForm from './CreateSLAForm'
import SLAListDisplayHeader from './SLAListDisplayHeader'

function SLAsListDisplay(){
	const [pageNo, setPageNo] = useState(0)
	const [pageSize, setPageSize] = useState(10)	
	const primaryCheckBoxRef = useRef(null)
	const [ids, setIds] = useState([])
	const groupButtonsRef = useRef(null)
	const [slaLine, setSLALine] = useState(null)

	useEffect(() => {
		loadSLAs();
		groupButtonsRef.current.style.visibility = 'hidden'		
	},[])//use effect

function loadSLAs(){
	axiosClient.getRequest(constants.SLA_URL_REQ+'?pageNo='+pageNo+'&pageSize='+pageSize)
	.then(function (response) {
	let slaList = response.data.map((element, index) => (					
					<SLALine sla= {element} checkBoxClicked={checkBoxClicked}/>					           
	));
		setSLALine((Object.keys(slaList).length === 0  ? 'No Records Found...' : slaList))
	}).catch(function (response) {
	    console.log(response);
	});

}
function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('sla-list-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked){
			idsTemp.push(id)
			if(event.target.checked){		
				groupButtonsRef.current.children[0].style.visibility = 'visible'
			}else{
				groupButtonsRef.current.children[0].style.visibility = 'hidden'
			}				
		}else{
		 const index = idsTemp.indexOf(id);
			if (index > -1) {
	  			idsTemp.splice(index, 1);
	  			if(idsTemp.length === 0){
	  				groupButtonsRef.current.children[0].style.visibility = 'hidden'
	  			}
			}
		}
	}
	setIds(idsTemp)
}

function checkBoxClicked(id, value){
	console.log('checkBoxClicked '+value)
	let idsTemp = ids;
	if(value){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.children[0].style.visibility = 'visible'
	}else{
		groupButtonsRef.current.children[0].style.visibility = 'hidden'
	}
	if(!value){
		primaryCheckBoxRef.current.checked = false
	}
}
function performAction(type){
	let payload = {'ids': ids}
	let url = null;
	if(type === 'delete')
		url = constants.SLA_URL_REQ+'delete';
	else if(type === 'block')
		url = constants.SLA_URL_REQ+'block';
	else if(type === 'spam')
		url = constants.SLA_URL_REQ+'spam';

//TODO: block becomes unblock
   	axiosClient.postRequest(url, payload)
       .then(function (response) {
           loadSLAs();
		let elements = document.getElementsByClassName('sla-list-checkbox')
		for (var i = 0; i < elements.length; i++) {
			if(elements[i].checked)
				elements[i].checked = false;		
		}           
       }).catch((error) => {
           console.log(error)
       }); 
       groupButtonsRef.current.style.visibility = 'hidden' 
       groupButtonsRef.current.children[0].style.visibility = 'hidden'
}

function searchSLAs(event){
	let pageNo=0;
	let pageSize=10;		
	axiosClient.getRequest(constants.SLA_URL+"search?keyword="+encodeURIComponent(event.target.value)+'&pageNo='+pageNo+'&pageSize='+pageSize)
		.then(function (response) {
		let slaList = null;
		slaList = response.data.map((element, index) => (					
						<SLALine sla= {element} checkBoxClicked={checkBoxClicked}/>
		));
		setSLALine((Object.keys(slaList).length === 0  ? ('No Records Found...') : slaList))
		}).catch(function (response) {
		    console.log(response);
		});
}

	return (
	<>
		<SLAListDisplayHeader searchSLAs={searchSLAs}
							  groupButtonsRef={groupButtonsRef}
							  performAction={performAction}/>

		<div class="main-body">
			<div class='container' style={{padding:'20px',paddingTop:'10px', minWidth:'100%',marginLeft:'0px', marginRight:'0px'}}>
		         <div class="row">
		            <div class="col-lg-12">
		               <div class>
		                  <div class="table-responsive">
		                     <table class="table table-nowrap align-middle table-borderless">
		                        <thead>
		                           <tr>
		                              <th scope="col" class="ps-4" style={{width:'50px', color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>
		                                 <div class="form-check font-size-16"><input type="checkbox" class="form-check-input" ref={primaryCheckBoxRef} style={{border:'1px solid #A9A9A9'}} onClick={onPrimaryCheckboxClicked} ref={primaryCheckBoxRef}/></div>
		                              </th>
		                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Name</th>
		                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Business Hours</th>
		                              <th scope="col" style={{color:'rgb(60, 103, 170)',backgroundColor:'#f4f4f4', borderBottom:'1px solid rgb(6, 55, 127)', fontSize:'16px', fontWeight:'800'}}>Priority</th>
		                           </tr>
		                        </thead>
		                        <tbody>
		                        	{slaLine}                    
		                        </tbody>
		                     </table>
		                  </div>
		               </div>
		            </div>
		         </div> 
		    </div>        		
		</div>
	</>
		);

	}
export default SLAsListDisplay;