import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import * as axiosClient from '../../../js/Axios';
import {Route, Link, Routes, useParams} from 'react-router-dom';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import ReactDOMServer from 'react-dom/server';
import TicketContactDisplay from './TicketContactDisplay';
import TicketLeftWindow from './TicketLeftWindow';
import TicketMiddleBox from './TicketMiddleBox'
import LoadingAnimation from '../../LoadingAnimation'
import TicketDisplayHeader from './TicketDisplayHeader'
import TicketDisplayMenu from './TicketDisplayMenu'
import TicketHistory from './TicketHistory'
import TicketHistoryLine from './TicketHistoryLine'
import TicketMergeWindow from './TicketMergeWindow'
import TicketResolutions from './TicketResolutions'
import '../css/ticket.css'

function TicketDisplay(props){ 
    const [merge, setMerge] = useState(false)
    const ticketLeftWindowRef = useRef(null)
    const privateCommentRef = useRef(null)       
    const [ticket,setTicket] = useState(null)
    const [summary, setSummary] = useState(null)
	  const params = useParams();
    const navigate = useNavigate();
    const replyBoxRef = useRef(null)
    const [displayType, setDisplayType] = useState('conversations')
    const [history, setHistory] = useState(null)

useEffect(() => {        
  axiosClient.getRequest(constants.TICKET_URL+params.id)
  .then(function (response) {
      if(response.data === ''){
          navigate(constants.TICKET_URL_ABS)
          return
      }
      setTicket(response.data)
      setSummary(response.data.summary)
      registerResizeEvent()
      onResizeEvent(true)
  }).catch((error) => {
     if(error.response !== undefined && error.response.data === 'Error while fetching ticket'){
        let div = document.createElement('div')
        div.innerHTML = "Either the ticket does not exist or you dont have permisions. Redirecting to home"
        //Todo Add sleep
        navigate(constants.TICKET_URL_ABS)
    }            
    console.log(error)
  });
},[])//use effect 

function cloneTicket(){

}
function displayMiddleboxFun(type){
  if(type === 'conversations')
    return <TicketMiddleBox ticket={ticket} replyBoxRef={replyBoxRef}  setSummary={setSummary} privateCommentRef={privateCommentRef}/>
  else if(type === 'history')
    <TicketHistory history={history}/>
  else if(type === 'resolutions')
    <TicketResolutions />
}
function openReplyBox(){
    replyBoxRef.current.style.display = 'block'
    replyBoxRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' })  
    if(privateCommentRef.current)
      privateCommentRef.current.checked = false
}
function addNote(){
  openReplyBox()
  privateCommentRef.current.checked = true
}
function displayMiddlebox(event, type){
  setDisplayType(type)
  displayMiddleboxFun(type)
}
function mergeTickets(){
  setMerge(true)
}
function closeMergeWindow(){
  setMerge(false)
}
function displayTicketLeftBox(){
  document.getElementsByClassName("Ticket-Left-Box")[0].style.display = 'block'
  onResizeEvent() 
}
function onResizeEvent(value){
  console.log('onResizeEvent')
  let bodyElement = document.getElementsByTagName("BODY")[0];
  let newWidth = bodyElement.offsetWidth; 
  if(!document.getElementsByClassName('Ticket-Left-Box')[0] ||
     !document.getElementsByClassName('ticket-display-main-body')[0] ||
     !document.getElementsByClassName('ticket-details-button')[0])
    return
  if(newWidth <= '1024'){

    document.getElementsByClassName('Ticket-Left-Box')[0].style.width='350px';
    if(value)
      document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'none'    
    else
      document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'block'    
    document.getElementsByClassName('ticket-display-main-body')[0].style.width='calc(100% - 75px)'
    document.getElementsByClassName('ticket-details-button')[0].style.display='inline'    
  }else if(newWidth <= '1120'){
    console.log('<1120')
    document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'block'
    document.getElementsByClassName('Ticket-Left-Box')[0].style.width='350px';
    document.getElementsByClassName('ticket-display-main-body')[0].style.width='calc(100% - 425px)'
    document.getElementsByClassName('ticket-details-button')[0].style.display='none'    
  }
  else if(newWidth <= '1220'){
    console.log('<1220')
    document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'block'
    document.getElementsByClassName('Ticket-Left-Box')[0].style.width='400px';
    document.getElementsByClassName('ticket-display-main-body')[0].style.width='calc(100% - 475px)'
    document.getElementsByClassName('ticket-details-button')[0].style.display='none'    
  }
  else{
    // if(value === true)
    //   return
    console.log('onResizeEvent else')
    document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'block'
    document.getElementsByClassName('Ticket-Left-Box')[0].style.width='450px';
    document.getElementsByClassName('ticket-display-main-body')[0].style.width='calc(100% - 525px)'
    document.getElementsByClassName('ticket-details-button')[0].style.display='none'    
  }
}
function closeTicketLeftBox(){
  document.getElementsByClassName('Ticket-Left-Box')[0].style.display = 'none'
  document.getElementsByClassName('ticket-display-main-body')[0].style.width='calc(100% - 75px)'
    document.getElementsByClassName('ticket-details-button')[0].style.display='inline'    
}

function registerResizeEvent(){
  var body = document.getElementsByTagName("BODY")[0];
  var width = body.offsetWidth;
  if (window.addEventListener) {
    window.addEventListener ("resize", onResizeEvent, true);
  } else {
    if (window.attachEvent) {
      window.attachEvent("onresize", onResizeEvent);
    }
  }
}


function updateResolutions(newResolution){
  console.log('updateResolutions')
  let ticketTemp  = ticket
  ticketTemp.resolutions.push(newResolution)
  setTicket(ticketTemp)
}

	return(
      <>    
        {  (ticket !== null )  ?
          <>
            {merge ? <TicketMergeWindow closeMergeWindow={closeMergeWindow}/> : '' }
            <div style={{display: 'flex', flexDirection:'column',justifyContent:'start'}}>
                  <TicketLeftWindow ticket={ticket} contact={ticket.reporter_contact} ref={ticketLeftWindowRef} closeTicketLeftBox={closeTicketLeftBox}/>
                  <div class="ticket-display-main-body">
                    <div class='ticket-display-header-body'>
                        <TicketDisplayHeader ticket={ticket} summary={summary} openReplyBox={openReplyBox} addNote={addNote}
                            displayMiddlebox={displayMiddlebox}
                            mergeTickets={mergeTickets}
                            displayTicketLeftBox={displayTicketLeftBox}/>
                    </div>                                                      
                     
                        <div class="Ticket-Page" style={{backgroundColor: '#EEEDED'}}>                      
                          {
                            (displayType ==='conversations') ? <TicketMiddleBox ticket={ticket} replyBoxRef={replyBoxRef}  setSummary={setSummary} privateCommentRef={privateCommentRef}/>
                            :(displayType ==='history') ? <TicketHistory history={history}/>
                            :<TicketResolutions ticket={ticket} replyBoxRef={replyBoxRef} updateResolutions={updateResolutions}/>                          
                          }
                        </div> 
                  </div>
            </div>
          </>
         : <LoadingAnimation />
        }
    </>  	
		);
}




export default TicketDisplay;