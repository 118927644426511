import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import {useNavigate} from "react-router-dom"
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import '../../../../css/main.css'
import { ToastContainer, toast } from 'react-toastify';
function ConfigurePhoneNumber(props){	
	const businessHoursRef = useRef(null)
	const [value, setValue] = useState(-1)
	const oooRef = useRef(null)
	const [edit, setEdit] = useState()
	const [businessHoursId, setBusinessHoursId] = useState(null)
	const dropdownRef = useRef(null)
	const selectRef = useRef(null)
	const phoneNumberRef = useRef(null)
	const valueRef = useRef(null)
	const errorRef = useRef(null)
	const navigate = useNavigate(null)
	const DROP_DOWN_POS = 3;
	const SELECT_POS = 1;
	const HIDDEN_INPUT = 4;
	const INPUT_POS=2
	useEffect(()=>{
		console.log("useeffect Configure PhoneNumber")
		//TODO check for phone numbers and then validate form
		// else redirect to number purchase
		if(phoneNumberRef.current.children.length === 0){
				axiosClient.getRequest(constants.ACCOUNT_URL+'phone_number/'+props.phoneNumberIdRef.current)
				.then(function (response) {
					
				    var option = document.createElement("option");
				    option.value = response.data.phoneNumber;
				    option.text = response.data.phoneNumber;	    
				    phoneNumberRef.current.appendChild(option);
				    if(response.data.routeTypeId !== 'null'){
				    	setEdit(true)
				    	oooRef.current.value = response.data.oooMessage
				    	businessHoursRef.current.value = response.data.businessCalender !== null ? response.data.businessCalender.name : ''
				    	selectRef.current.value = response.data.routeType
				    	valueRef.current.value = response.data.valueName
				    	setValue(response.data.routeTypeId)
				    }	
				}).catch(function (error) {
				    displayError(error.response.data.error)
				});	
		}	
	},[])	

function handleBusinessHours(event){
	  event.target.setCustomValidity('');
      event.target.parentElement.children[1].innerHTML = ''      
      setBusinessHoursId(-1)
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.BUSINESS_CALENDER_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToBHDropdown(element,event)
          })
          event.target.parentElement.children[1].style.display = 'block'
      }).catch(function (response) {
          console.log(response);
      });
}
  function onSelectDropdown(event){
      event.target.parentElement.parentElement.children[0].value = event.target.innerHTML
      event.target.parentElement.parentElement.children[1].style.display = 'none'
      setBusinessHoursId(event.target.name)      
  }

  function addChildToBHDropdown(element, event){
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.onclick = onSelectDropdown
      event.target.parentElement.children[1].appendChild(aTag)
      event.target.parentElement.children[1].style.display = 'block'
  } 	

function fetchValues(event){
	setValue(-1);
	if(event.target.value.length < 3)
		return
	let arr = {'Call Route' : 'call_route',
				'IVR' : 'ivr'}
	console.log(selectRef.current.value)
	axiosClient.getRequest(constants.CALL_URL+arr[selectRef.current.value]+'/search?keyword='+event.target.value)
	.then(function (response) {
		dropdownRef.current.innerHTML = ''  
		response.data.forEach((element, index)=>{				
				addChildToDropdown( element)
        })				
	}).catch(function (error) {
		console.log(error)
	    displayError(error.response.data.error)
	});
}

	function addChildToDropdown(element){
      let aTag = document.createElement('a')
      aTag.name=element.id      
      aTag.innerHTML = element.name
      aTag.onclick = (event) => onSelectValueDropdown(event, element.id, element.name)
      dropdownRef.current.appendChild(aTag)
      dropdownRef.current.style.display='inline'
	}

	function onSelectValueDropdown(event,id,name){
	  setValue(id)
	  valueRef.current.value = name
	  dropdownRef.current.innerHTML = '' 
	  dropdownRef.current.style.display='none' 
	}
	function selected(event){
		dropdownRef.current.innerHTML = ''
		setValue(-1)
		valueRef.current.value = ''
		errorRef.current.innerHTML = ''
		errorRef.current.style.display = 'none'			
	}

	function validateForm(event){
		if(value === -1){
			displayError('Please create '+selectRef.current.value+' and search for a value')		
			return false
		}
		return true
	}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}	
	function handleSubmit(event){
		console.log("handleSubmit")
		errorRef.current.innerHTML = ''
		errorRef.current.style.display = 'none'		
		event.preventDefault()
		if(!validateForm(event)){
			return
		}
		let payload = {}
		payload['id'] = props.phoneNumberIdRef.current
		payload['phoneNumber'] = event.target.phoneNumber.value
		payload['ooo'] = event.target.ooo.value
		payload['type'] = selectRef.current.value
		payload['typeId'] = value
		payload['businessHoursId'] = businessHoursId
		console.log("payload is "+JSON.stringify(payload))
			axiosClient.postRequest(constants.CALL_URL+'configure_phone_number',payload)
			.then(function (response) {				
				toast.success('Phone Number Configured... ')
				navigate(constants.ADMIN_URL_ABS+'phone_numbers')							
			}).catch(function (error) {
				console.log(error)
			    displayError(error.response.data.error)
			});	
	}
	return(<div class="create-wrapper">		
				<form onSubmit = {handleSubmit}>
				  <div style={{boxShadow:'0 0 2px grey', padding:'30px', paddingleft:'0px',backgroundColor:'#F5F5F5F5',height: 'calc(100vh - 105px', overflowY: 'scroll'}}>
					<a href="javascript:void(0)" class="create-closebtn" onClick={props.closeConfigurePhoneNumberWindow}>&times;</a>
					<h8 style={{color:'#044caf',fontWeight:'800', fontSize:'20px'}}>Configure Phone Number </h8>
					<div class='create-line'></div>
					<div style={{marginTop: '3%'}} class='create-form-label'>Select Phone Number: 
						<select name='phoneNumber' ref={phoneNumberRef} style={{width:'50%', padding: '5px', marginLeft: '3%'}}></select>
					</div>
				   <div class='create-form-label' style={{marginTop: "20px"}}>Business Hours:</div>              
	               <div style={{width: "90%", position: 'relative'}}>
	                 	<input name="businessHours" style={{width: "90%", minWidth:'450px', height: "33px"}} placeholder={'Business Hours...'} onChange={handleBusinessHours} ref={businessHoursRef}/>
	                 	<div style={{display:'none' }} class='bh-dropdown-content'/>
	               </div>					
					<div style={{marginTop: '10px',width: '90%'}} class='create-form-label'>Out of Business hours:</div>
					<div style={{width: '90%'}}><input type = "text" placeholder = "Message" name='ooo' style={{width: '90%', minWidth:'450px'}} ref={oooRef}/></div>
					<div style={{marginTop: '3%'}} class='create-form-label'>Direct Incoming Call To </div>
					<select ref={selectRef} onChange={selected} style={{width:'40%', padding: '5px', borderRadius: '5px'}}><option name='ivr'>IVR</option><option name='call_route'>Call Route</option></select>
					<div style={{display:'inline-block', position:'relative',width:'40%'}} class="configure-phone-dropdown-content">
						<input type = "text" placeholder = "Search..." ref= {valueRef} onChange={fetchValues} style={{width:'100%', marginLeft: '3%'}}/>
						<div ref={dropdownRef} style={{width: '100%' }} class='phone-number-configure-dropdown'/>
						<input  style={{display: "none"}} value = {-1}/>
					</div>
					<div><button class='ss-button'  class='create-button'>Configure Number</button></div>
				  </div>
				</form>
			<div ref={errorRef} />
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>			
		  </div>
		  )
}

export default ConfigurePhoneNumber;