import React, { useState, useEffect, useRef } from "react";
import * as axiosClient from '../../../../js/Axios';
import * as constants from '../../../../js/constants'
import {ToastContainer, toast } from 'react-toastify';

const styles = {
  container: {
    maxWidth: "75%",
    margin: "auto",
    padding: "20px",
    fontFamily: "Arial, sans-serif",
    backgroundColor: "#f9f9f9",
    borderRadius: "8px",
    boxShadow:
      "rgba(0, 0, 0, 0.16) 0px 10px 36px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px",
    overflowY: "auto",
  },
  header: {
    textAlign: "center",
    marginBottom: "20px",
    color:"rgb(4, 76, 175)",
    fontWeight:'bold'
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  field: {
    marginBottom: "15px",
  },
  label: {
    marginBottom: "5px",
    fontWeight: "bold",
    display: "block",
  },
  input: {
    width: "100%",
    // padding: "8px",
    border: "1px solid #ccc",
    borderRadius: "4px",
  },
  colorInput: {
    padding: "4px",
  },
  preview: {
    marginTop: "10px",
  },
  iconPreview: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "8px",
    border: "1px solid #ccc",
  },
  instructionBox: {
    backgroundColor: "",
    padding: "15px",
    borderRadius: "8px",
    marginBottom: "20px",
    border: "1px solid #91d5ff",
  },
  codeBox: {
    backgroundColor: "rgb(230, 240, 255)",
    padding: "10px",
    borderRadius: "4px",
    fontFamily: "monospace",
    overflowX: "auto",
    whiteSpace: "pre-wrap",
    border: "1px solid #d9d9d9",
    fontWeight:'600'
  },
  button: {
    backgroundColor: "#0078d7",
    color: "white",
    border: "none",
    padding: "10px 15px",
    borderRadius: "4px",
    cursor: "pointer",
  },
};
const ChatWindowSettings = () => {
  const [settings, setSettings] = useState({
    chatTitle: "",
    welcomeMessage: "",
    themeColor: "#0078d7",
    fontStyle: "Arial",
    chatPosition: "bottom-right",
    showPreChatForm: true,
    offlineMessage: "",
    showTypingIndicator: true,
    soundNotifications: true,
    conversationTimeout: 30,
    customCSS: "",
    companyIcon: null,
  });

  const [previewIconUrl, setPreviewIconUrl] = useState(null);
  const [siteKey, setSiteKey] = useState(""); // Store the siteKey from the backend
  const [whitelistedDomains, setWhitelistedDomains] = useState([]); // List of whitelisted domains
  const [newDomain, setNewDomain] = useState(""); // Input for adding a new domain
  const spinnerRef = useRef(null)
  const titleRef = useRef(null)
  const themeColorRef = useRef(null)
  const [image, setImage] = useState(null)
  // Fetch siteKey from the backend
  useEffect(() => {
      axiosClient.getRequest(constants.CHAT_WINDOW_URL)
      .then(function (response) {      
          titleRef.current.value = response.data.chatTitle
          themeColorRef.current.value = response.data.themeColor
          setWhitelistedDomains(response.data.whiteListedDomains !== "" && response.data.whiteListedDomains !== "null" ? response.data.whiteListedDomains.split(";") : [])
          setSiteKey(response.data.token)
          setPreviewIconUrl(response.data.image !== "" && response.data.image !== "null" ? response.data.image : null)
      }).catch((error) => {

      });   
  }, []);

  useEffect(() => {
    if (settings.companyIcon) {
      const iconUrl = URL.createObjectURL(settings.companyIcon);
      setPreviewIconUrl(iconUrl);

      return () => URL.revokeObjectURL(iconUrl); // Cleanup preview URL on unmount
    }
  }, [settings.companyIcon]);

function isValidUrl(url) {
  const urlPattern = /^(https?:\/\/)?([\w\-]+(\.[\w\-]+)+)(:\d+)?(\/[^\s]*)?$/i;
  return urlPattern.test(url);
}
const handleAddDomain = () => {
  console.log('handleAddDomain')
  const trimmedDomain = newDomain.trim();
  if(!isValidUrl(trimmedDomain)){
    toast.error('Not a valid url')
    return
  }
  if (!trimmedDomain) {
    alert("Domain cannot be empty."); // Alert user if input is empty
    return;
  }
  if (whitelistedDomains.includes(trimmedDomain)) {
    alert("Domain is already whitelisted."); // Prevent duplicates
    return;
  }
  setWhitelistedDomains([...whitelistedDomains, trimmedDomain]);
  setNewDomain(""); // Clear the input field after adding
};


  const handleRemoveDomain = (domain) => {
    setWhitelistedDomains(whitelistedDomains.filter((d) => d !== domain));
  };


  const handleSubmit = (event) => {
    event.preventDefault();
    let payload = {chatTitle:  titleRef.current.value,
                    themeColor: themeColorRef.current.value,
                    whitelistedDomains: whitelistedDomains,
                    imageName: image === null ? null : 'image'
                  }

    console.log('sending post request '+JSON.stringify(payload))
      axiosClient.patchRequest(constants.CHAT_WINDOW_URL, payload,{headers: {
        'Content-Type': 'multipart/form-data'
      }})
      .then(function (response) {      
        if(image !== null){
            axiosClient.putExternalRequest(response.data.image, image,{headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Length': image.size
            }})
            .then(function (response2) { 
              console.log('Chat Window Settings saved '+JSON.stringify(response.data))
                spinnerRef.current.style.display = 'none'
                toast.success('Chat Window Settings saved ...')
            }).catch((error) => {
              spinnerRef.current.style.display = 'none'
            })  
        } else{
                spinnerRef.current.style.display = 'none'
                toast.success('Chat Window Settings saved ...')          
        }
      }).catch((error) => {
          if(error.response?.status === 400){
            if(error.response.data.error === '')
              toast.error('Error in processing the request')
            else
              toast.error(error.response.data.error)
          }else{
            toast.error('Error in processing the request')
          }
          spinnerRef.current.style.display = 'none'
      });       
  };

function handleColorThemeChange(event){
  console.log('handleColorThemeChange '+ event.target.value)
  themeColorRef.current.value = event.target.value
}

function handleFileUpload(event){
    const file = event.target.files[0]; // Get the first selected file
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setPreviewIconUrl(fileUrl); // Set the preview URL     
      setImage(file) 
    }     
}

  const scriptSnippet = `<script>
  (function() {
    const chatSettings = {
      siteKey: "${siteKey}"
    };
    const script = document.createElement("script");
    script.src = "https://yourdomain.com/chat-widget.js";
    script.async = true;
    document.body.appendChild(script);
  })();
</script>`;

  return (
  <>
    <div className="main-body">
      <div style={{ overflowY: "auto", maxHeight: "100%" }}>
        <div style={styles.container}>
          <h2 style={styles.header}>Chat Window Settings</h2>
          <form onSubmit={handleSubmit} style={styles.form}>
            <div style={styles.field}>
              <label style={styles.label}>Chat Window Title:</label>
              <input
                type="text"
                name="chatTitle"
                style={styles.input}
                ref={titleRef}
              />
            </div>

            <div style={styles.field}>
              <label style={styles.label}>Company Icon:</label>
              <input
                type="file"
                name="companyIcon"
                accept="image/*"
                style={styles.input}
                onChange={handleFileUpload}
              />
              {previewIconUrl && (
                <div style={styles.preview}>
                  <img
                    src={previewIconUrl}
                    alt="Company Icon"
                    style={styles.iconPreview}
                  />
                </div>
              )}
            </div>

            <div style={styles.field}>
              <label style={styles.label}>Theme Color:</label>
              <input
                type="color"
                name="themeColor"
                onChange={handleColorThemeChange}
                ref={themeColorRef}
              />
            </div>

            {/* Domain Whitelisting */}
            <div style={styles.field}>
              <label style={styles.label}>Whitelisted Domains:</label>
              <div style={styles.domainList}>
                {whitelistedDomains.map((domain, index) => (
                  <div key={index} style={styles.domainItem}>
                    <span>{domain}</span>
                    <button
                      type="button"
                      onClick={() => handleRemoveDomain(domain)}
                      class="ss-button"
                      style={{marginLeft:'20px'}}
                    >&times;
                    </button>
                  </div>
                ))}
              </div>
              <input
                type="url"
                value={newDomain}
                onChange={(e) => setNewDomain(e.target.value)}
                placeholder="Add domain (e.g., example.com)"
                style={styles.input}
              />
              <button
                type="button"
                onClick={handleAddDomain}
                style={styles.addButton}
                 class='ss-button'
              >
                Add Domain
              </button>
            </div>
            <div style={styles.instructionBox}>
              <h3 style={{fontWeight:'bold'}}>Insturctions to Load the Chat Window in your Site</h3>
              <p>
                To load this chat window on your site, follow these steps:
              </p>
              <h4>1. Add the following snippet in the <code>&lt;head&gt;</code> section before the closing <code>&lt;/head&gt;</code> tag:</h4>
              <pre style={styles.codeBox}>
                {`<script type="text/javascript">\n\twindow.ssChatToken = {\n\t\tsiteKey: "${siteKey}"\n\t}\n</script>`}
              </pre>
              <h4>2. Include the chat widget script after the <code>&lt;/body&gt;</code> tag:</h4>
              <pre style={styles.codeBox}>
                {`<script src="https://chat.supportspoc.com"></script>`}
              </pre>
            </div>


            <button type="submit" style={styles.button}>
              Save Settings
            </button>
          </form>
        </div>
      </div>
       
    </div>
        <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>
      <div ref={spinnerRef} />                
    </>
  );
};



export default ChatWindowSettings;
