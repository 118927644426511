import React, { useState, useEffect, useRef } from "react";
import CategoryLine from './CategoryLine';
import Article from './Article';
import * as axiosClient from "../../../js/Axios";
import * as constants from '../../../js/constants';
import '../css/kb.css';
import 'quill/dist/quill.snow.css';
import { ToastContainer, toast } from 'react-toastify';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { usePermissions } from '../../PermissionsProvider';
import CreateArticle from './CreateArticle'

function KB() {
  const { isActionAllowed, isAgent } = usePermissions();
  const [categories, setCategories] = useState([]);
  const [articles, setArticles] = useState([]);
  const [article, setArticle] = useState(null);
  const [edit, setEdit] = useState(false);
  const [list, setList] = useState(true);
  const nameRef = useRef(null);
  const descriptionRef = useRef(null);
  const indexRef = useRef(null);
  const showCreateCategoryRef = useRef(null);
  const [categoriesBackup, setCategoriesBackup] = useState([])
  const [showCreateArticle, setShowCreateArticle] = useState(false)
  const editArticleIdRef = useRef(null)
  const subCategoryIdRef = useRef(null)
  const [firstCategoryId, setFirstCategoryId] = useState(-1)

  useEffect(() => {
    loadCategories();

  }, []);

  document.addEventListener('click', (event) => {
    if (!event.target.closest('.popover-positioned-bottom')) {
      let eles = document.getElementsByClassName('popover-positioned-bottom');
      for (let ele of eles) {
        ele.style.display = 'none';
      }
    }
  });

  
  const loadCategories = () => {
    axiosClient.getRequest(constants.KB_URL + 'categories')
      .then((response) => {
          setCategories(response.data);
          setCategoriesBackup(response.data);
          // setRender(!render)
          const updatedCategories = response.data;
          for (let i = 0; i < updatedCategories.length; i++) {
              const category = updatedCategories[i];
              let found = false
              if (category.subCategories && category.subCategories.length > 0) {
                for (let j = 0; j < category.subCategories.length; j++) {
                  const subCategory = category.subCategories[j];
                  if (subCategory.articles && subCategory.articles.length > 0) {
                    found = true
                    setArticles(subCategory.articles)
                    setFirstCategoryId(category.id)
                    break;
                  }
                }
              }
              if(found)
                break
          }
      })
      .catch((err) => {
        console.error("Error loading categories", err);
        toast.error("Failed to load categories.");
      });
  };

  const displayContent = (id) => {
    axiosClient.getRequest(constants.KB_URL + 'articles?id=' + id)
      .then((response) => {
        setArticle(response.data);
        setList(false);
      })
      .catch((err) => {
        console.error("Error fetching article content", err);
        toast.error("Failed to load article.");
      });
  };

  function editArticle(id){
      editArticleIdRef.current = id
      setShowCreateArticle(true)
  }

  function returnToKB(){
      console.log('returnToKB')
      editArticleIdRef.current = null
      setShowCreateArticle(false)
      let eles = document.getElementsByClassName('popover-positioned-bottom');
      for (let ele of eles) {
        ele.style.display = 'none';
      }
  }

  function deleteSubCategory(id){
    axiosClient.patchRequest(constants.KB_URL+'sub_category/delete/'+id)
      .then(function (response) {
        // setCategories((prevCategories) =>
        //     prevCategories.map((category) => ({
        //       ...category,
        //       subCategories: category.subCategories.filter(
        //         (sub) => sub.id !== id
        //       ),
        //     }))
        //   );
        setCategories([])
        loadCategories()
      }).catch(function (response) {
          console.log(response);
      });
  }


  function deleteArticle(id){
      axiosClient.postRequest(constants.KB_URL + 'article/delete/'+id)
      .then((response) => {
            setCategories((prevCategories) => {
                  const updatedCategories = prevCategories.map((category) => {
                    if (category.subCategories && category.subCategories.length > 0) {
                      const updatedSubCategories = category.subCategories.map((subCategory) => {
                        if (String(subCategory.id) === String(subCategoryIdRef.current)) {
                          // Filter out the article by its articleId
                          const updatedArticles = subCategory.articles.filter(
                            (article) => String(article.id) !== String(id)
                          );
                          setArticles(updatedArticles); // If needed, update the global articles state
                          return { ...subCategory, articles: updatedArticles };
                        }
                        return subCategory;
                      });

                      return { ...category, subCategories: updatedSubCategories };
                    }
                    return category;
                  });

                  return updatedCategories;
            });

          setArticles((prevArticles) => {
            const updatedArticles = prevArticles.filter(
              (article) => String(article.id) !== String(id)
            );
            return updatedArticles;
          });
      })
      .catch((err) => {
        console.error("Error loading categories", err);
        toast.error("Failed to load categories.");
      });
  }

  const renderPopover = (article) => (
    <Popover  style={{ padding: '10px',width:'130px' }} class = 'popover-positioned-bottom'>
      {(article.status === 'Draft' && (isAgent() || isActionAllowed('PUBLISH_ARTICLE'))) && (
        <div style={{ cursor: 'pointer', padding: '5px', fontWeight: '800' }} onClick={() => publishArticle(article.id)}>
           Publish
        </div>       
      )}
      {article.status === 'Published' && (isAgent() || isActionAllowed('UNPUBLISH_ARTICLE')) && (
        <div style={{ cursor: 'pointer', padding: '5px', fontWeight: '800' }} onClick={() => unPublishArticle(article.id)}>
           Unpublish
        </div>       
      )}
      {(isAgent() || isActionAllowed('EDIT_ARTICLE')) && 
        (<div style={{ cursor: 'pointer', padding: '5px', fontWeight: '800' }} onClick={() => editArticle(article.id)}>
          Edit
        </div>)
      }
      {(isAgent() || isActionAllowed('DELETE_ARTICLE')) && 
        (<div style={{ cursor: 'pointer', padding: '5px', fontWeight: '800' }} onClick={() => deleteArticle(article.id)}>
          Delete
        </div>)
      }
    </Popover>
  );

  const createCategory = (event) => {
    event.preventDefault();
    const payload = { name: event.target.name.value, description: event.target.description.value };
    const url = edit ? constants.KB_URL + 'category/' + event.target.index.value : constants.KB_URL + 'category';
    const request = edit ? axiosClient.patchRequest(url, payload) : axiosClient.postRequest(url, payload);

    request
      .then(() => {
        loadCategories();
        setEdit(false);
        showCreateCategoryRef.current.style.display = 'none';
      })
      .catch((err) => {
        console.error("Error creating/updating category", err);
        toast.error("Failed to create/update category.");
      });
  };

  const openCategory = (name, description, index) => {
    nameRef.current.value = name;
    descriptionRef.current.value = description;
    indexRef.current.value = index;
    setEdit(true);
    showCreateCategoryRef.current.style.display = 'block';
  };

  const displayCreateCategoryBox = () => {
    setEdit(false);
    showCreateCategoryRef.current.style.display = 'block';
  };

  const closeCreateCategoryForm = () => {
    showCreateCategoryRef.current.style.display = 'none';
    setEdit(false);
  };

  const publishArticle = (id) => {
    console.log('publish Article '+JSON.stringify(categories))
    axiosClient.postRequest(constants.KB_URL + 'publish_article?id=' + id)
      .then((response) => {
    		setCategories(prevCategories => {
    		    const updatedCategories = prevCategories.map(category => {
    		      if (category.subCategories && category.subCategories.length > 0) {
    		        const updatedSubCategories = category.subCategories.map(subCategory => {
    		          if (subCategory.articles && subCategory.articles.length > 0) {
                    let found = false;
                    const updatedArticles = subCategory.articles.map((article) => {
                        if (String(article.id) === String(id)) {
                            found = true;
                            return { ...article, ...response.data };
                          }
                          return article;
                        });

                        if (found){ setArticles(updatedArticles); found = false}
                        return { ...subCategory, articles: updatedArticles };
                    }                
    		          return subCategory;
    		        });

    		        return { ...category, subCategories: updatedSubCategories };
    		      }
    		      return category;
    		    });

    		    return updatedCategories;
    		  });		
            toast.success("Article published successfully");

            let eles = document.getElementsByClassName('popover-positioned-bottom');
            for (let ele of eles) {
              ele.style.display = 'none';
            }

          })
      .catch((err) => {
        console.error("Error publishing article", err);
        toast.error('Unable to publish article.');
      });
  };

  function unPublishArticle(id){

  }
  const handleSearch = (event) => {
      console.log('handleSearch '+event.target.value)
      if(event.target.value === ''){
        return setCategories(categoriesBackup);
      }
      const filtered = categoriesBackup.filter(category =>
        category.name.toLowerCase().startsWith(event.target.value.toLowerCase())
      );
      setCategories(filtered);
  };

	function displayArticles(articlesssss, subCategoryId){
    if(subCategoryId)
      subCategoryIdRef.current = subCategoryId
    else
      subCategoryIdRef.current = null
      axiosClient.getRequest(constants.KB_URL + 'subcategory/articles?id='+subCategoryId)
      .then((response) => {
        setArticles(response.data)
      })
      .catch((err) => {
        console.error("Error loading categories", err);
        toast.error("Failed to load categories.");
      }); 
		setList(true)			
	}
  function displayArticlesAgain(){
      setArticles(articles)
      setList(true) 
  }
  function newArticleCreated(article, categoryId, subCategoryId){
    axiosClient.getRequest(constants.KB_URL + 'categories')
      .then((response) => {
          setCategories(response.data);
          setCategoriesBackup(response.data);          
          setShowCreateArticle(false)

          const updatedCategories = response.data;
          for (let i = 0; i < updatedCategories.length; i++) {
              const category = updatedCategories[i];
              let found = false
              if (category.subCategories && category.subCategories.length > 0) {
                for (let j = 0; j < category.subCategories.length; j++) {
                  const subCategory = category.subCategories[j];
                  if (subCategory.id === subCategoryId) {
                    found = true
                    setArticles(subCategory.articles)
                    setFirstCategoryId(categoryId)
                    break;
                  }
                }
              }
              if(found)
                break
          }
      })
      .catch((err) => {
        console.error("Error loading categories", err);
        toast.error("Failed to load categories.");
      });
  }

 function displayStatus(status){
    if(status === 'Draft')
      return <span style={{color: 'white', backgroundColor: '#ff7400', padding:'5px 20px', width:'100px', display:'inline-block'}}>{status}</span>
    else if(status === 'Published')
      return <span style={{color: '#fff', backgroundColor: '#28a745',  padding:'5px 20px', width:'100px', display:'inline-block'}}>{status}</span>
 }


function displayCreateButton(){

}
  return (
    <>
      <div className="header-body">
        <div className="main-header">
          <div style={{ display: 'inline-block', float: 'left', textAlign: 'baseline' }}>
            <b>Knowledge Base</b>
          </div>
          <div style={{ display: 'inline-block', right: '0px', position: 'absolute' }}>
           {(isActionAllowed('CREATE_CATEGORY') || isAgent()) && (<button className='ss-button' onClick={displayCreateCategoryBox}>+ New Category</button>)}
          </div>
        </div>
      </div>
      {!showCreateArticle ?<>
      <div className='main-body'>
        <div style={{ padding: '10px', position: 'absolute', left: '0px', width: '350px', borderRight: '1px solid #dee2e6', minWidth: '250px', height: '100%', overflowY: 'auto', paddingLeft: '20px' }}>
          <input type='text' style={{ width: '100%' }} className='form-control' placeholder='search categories'  onChange={handleSearch}/>
          <div style={{ marginTop: '20px', fontSize: '26px', fontWeight: '600' }}>Categories</div>
          {
	          	categories.map((element, index) => (
	          		<CategoryLine key={element.id} name={element.name} element={element} openCategory={openCategory} displayArticles={displayArticles} editArticle={editArticle} firstCategoryId={firstCategoryId} deleteSubCategory={deleteSubCategory} />
	        	))
          }
        </div>
        <div style={{ position: 'absolute', left: '350px', width: 'calc(100% - 350px)', height: '100%', overflowY: 'auto' }}>
          {list ? (
            <table className='table'>
              <thead>
                <tr><th>Name</th><th>Visible in Agent Portal</th><th>Visible in Customer Portal</th><th>Status</th><th></th></tr>
              </thead>
              <tbody>
                  {articles && articles.length > 0 ? (
                    [...articles].reverse().map((article, index) => (
                      <tr key={index}>
                        <td>
                          <a href="#" onClick={() => displayContent(article.id)} style={{fontWeight:'600', textDecoration: 'none' }}>
                            {article.name}
                          </a>
                        </td>
                        <td>{article.apVisible === 'false' ? 'No' : 'Yes'}</td>
                        <td>{article.cpVisible === 'false' ? 'No' : 'Yes'}</td>
                        <td>{displayStatus(article.status)}</td>
                        <td style={{ width: '100px' }}>
                          <OverlayTrigger trigger="click" placement="bottom" overlay={renderPopover(article)}>
                            <img
                              src={require('../../../img/dots.png')}
                              alt=""
                              style={{ height: '15px', width: '15px', cursor: 'pointer' }}
                              id="overlay-trigger-dots"
                            />
                          </OverlayTrigger>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" style={{color:'rgb(60, 103, 170)', textAlign: 'center', fontWeight: '500', padding: '20px' }}>
                        No articles found in subcategory
                      </td>
                    </tr>
                  )}

              </tbody>
            </table>
          ) : (
            <Article article={article} displayArticlesAgain={displayArticlesAgain}/>
          )}
        </div>
      </div>

      <div className="kb-modal" ref={showCreateCategoryRef} style={{ display: 'none' }}>
        <div className="kb-modal-content">
          <span onClick={closeCreateCategoryForm} style={{ position: 'absolute', right: '30px', cursor: 'pointer', fontSize: '20px', zIndex: '100' }}>&times;</span>
          <h4 style={{ opacity: '0.8' }}>{edit ? 'Update Category' : 'Create Category'}</h4>
          <form onSubmit={createCategory}>
            <div className='create-form-label' style={{ marginTop: "3%" }}>Name:<p className='red-color'>*</p></div>
            <div><input type="text" name="name" style={{ width: "90%", paddingLeft: '5px' }} placeholder={'Name'} required maxlength='500' ref={nameRef} /></div>
            <div className='create-form-label' style={{ marginTop: "3%" }}>Description:</div>
            <div><textarea rows='5' name="description" style={{ width: "90%" }} placeholder={'Description'} ref={descriptionRef} /></div>
            <input style={{ display: "none" }} name='index' ref={indexRef} />
           <button className='ss-button'>{edit ? 'Update Category' : 'Create Category'}</button>
          </form>
        </div>
      </div>
      
    </>
     : <CreateArticle   editArticleIdRef={editArticleIdRef} returnToKB={returnToKB} newArticleCreated={newArticleCreated}/> }

     <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
        newestOnTop={false} closeOnClick rtl={false} 
        draggable pauseOnHover theme="colored" />
    </>    
  );
}

export default KB;
