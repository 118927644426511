import React from "react"
import {useState, setState, useRef, useEffect} from 'react';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import * as axiosClient from "../../../js/Axios"
import * as constants from '../../../js/constants'
import DraggableTable from './DraggableTable'
import Select from "react-select";

function CustomFieldsList(){
	const [ids,setIds] = useState([])
	const primaryCheckBoxRef = useRef(null)
	const groupButtonsRef = useRef(null)
	const [name, setName] = useState('Tickets')
	const showCreateCategoryRef = useRef(null)
	const [edit, setEdit] = useState(-1)
	const [categories, setCategories] = useState(null)
	const nameRef = useRef(null)
	const descriptionRef = useRef(null)
	const indexRef = useRef(null)
	const activeRef = useRef(null)
	const apLabelRef = useRef(null)
	const cpLabelRef = useRef(null)
	const showCreateWindowRef = useRef(null)
	const apVisibleWhileCreationRef  = useRef(null)
	const apMandatorySubmissionRef  = useRef(null)
	const apEditableRef  = useRef(null)
	const apMandatoryStatusChangeRef  = useRef(null)
	const apVisibleAfterCreationRef  = useRef(null)
	const apMandatoryResolutionRef  = useRef(null)
	const cpMandatoryRef  = useRef(null)
	const cpEditableRef  = useRef(null)
	const cpVisibleRef  = useRef(null)
	const groupRef = useRef(null)
	const fieldTypeRef = useRef(null)
	const valuesRef = useRef(null)
	const [selectedOptions, setSelectedOptions] = useState([]);
	const ticketsRef = useRef(null)
	const agentsRef = useRef(null)
	const contactsRef = useRef(null)
	const companyRef = useRef(null)
	const placeholderRef = useRef(null)
	const [displayPlaceholder, setDisplayPlaceholder] = useState(true)
	 
	const ticketFields = [
	    { apLabel: "Summary", type: "Text Box", AP:'Summary',CP: 'Summary' },	    
	    { apLabel: "Description", type: "Text Area", AP:'Description',CP: 'Description' },
	    { apLabel: "Priority", type: "Dropdown", AP:'Priority',CP: 'Priority'},
	    { apLabel: "Reporter", type: "Dropdown", AP:'Reporter',CP: 'Reporter' },
	    { apLabel: "Team", type: "Dropdown", AP:'Team',CP: 'Team' },
	    { apLabel: "Assignee", type: "Dropdown", AP:'Assignee',CP: 'Assignee'},	    
	    { apLabel: "Stataus", type: "Dropdown", AP:'Stataus',CP: 'Stataus' },
	    { apLabel: "Type", type: "Dropdown", AP:'Type',CP: 'Type' }
	 ]
	const contactFields = [
		 { apLabel: "Full Name", type: "Text Box", AP:'Full Name',CP: 'Full Name' },
		 { apLabel: "Email", type: "Text Box", AP:'Email',CP: 'Email' },
		 { apLabel: "Contact Number", type: "Text Box", AP:'Contact Number',CP: 'Contact Number' },
		 { apLabel: "Work Phone", type: "Text Box", AP:'Work Phone',CP: 'Work Phone' },
		 { apLabel: "Unique Id", type: "Text Box", AP:'Unique Id',CP: 'Unique Id' },
		 { apLabel: "Company", type: "Text Box", AP:'Company',CP: 'Company' },
		 { apLabel: "Address", type: "Text Area", AP:'Address',CP: 'Address' },		 
		 { apLabel: "Notes", type: "Text Area", AP:'Notes',CP: 'Notes' }
	]
	const companyFields = [		 
		 { apLabel: "Name", type: "Text Box", AP:'Name',CP: 'Name' },
		 { apLabel: "Email", type: "Text Box", AP:'Email',CP: 'Email' },
		 { apLabel: "Phone Number", type: "Text Box", AP:'Phone Number',CP: 'Phone Number' },
		 { apLabel: "Company Number", type: "Text Box", AP:'Company Number',CP: 'Company Number' },
		 { apLabel: "WorkPhone", type: "Text Box", AP:'WorkPhone',CP: 'WorkPhone' },
		 { apLabel: "Website", type: "Text Box", AP:'Website',CP: 'Website' },
		 { apLabel: "Address", type: "Text Area", AP:'Address',CP: 'Address' },
		 { apLabel: "Notes", type: "Text Area", AP:'Notes',CP: 'Notes' },		 
	]

	const agentFields = [		 
		 { apLabel: "Full Name", type: "Text Box", AP:'Full Name',CP: 'Full Name' },
		 { apLabel: "Email", type: "Text Box", AP:'Email',CP: 'Email' },
		 { apLabel: "Mobile Number", type: "Text Box", AP:'Mobile Number',CP: 'Mobile Number' },
		 { apLabel: "WorkPhone", type: "Text Box", AP:'WorkPhone',CP: 'WorkPhone' },
		 { apLabel: "Role", type: "Drop Down", AP:'Name',CP: 'Role' },
		 { apLabel: "Timezone", type: "Drop Down", AP:'Timezone',CP: 'Timezone' },
		 { apLabel: "Address", type: "Text Box", AP:'Address',CP: 'Address' }		 		 
	]
	const [rows, setRows] = useState(ticketFields);


	const options = [
	    { value: "option1", label: "Option 1" },
	    { value: "option2", label: "Option 2" },
	    { value: "option3", label: "Option 3" },
	    { value: "option4", label: "Option 4" },
	];

	  const handleChange = (selected) => {
	    setSelectedOptions(selected);
	  };

	  useEffect(()=>{
	  		groupButtonsRef.current.style.display='none'	  		
	  		loadCustomFields()
	  },[name])

	  function loadCustomFields(){
	  		ticketsRef.current.style.color = '#808080'
	  		agentsRef.current.style.color = '#808080'
	  		contactsRef.current.style.color = '#808080'
	  		companyRef.current.style.color = '#808080'
	  		let rowsCurr = []
	  		if(name === 'Tickets'){
	  			rowsCurr = ticketFields
	  			ticketsRef.current.style.color = 'rgb(60, 103, 170)'
	  			ticketsRef.current.style.fontWeight = '1000'
	  		}
	  		else if(name === 'Agents'){
	  			rowsCurr = agentFields
	  			agentsRef.current.style.color = 'rgb(60, 103, 170)'
	  			agentsRef.current.style.fontWeight = '1000'
	  		}
	  		else if(name === 'Contacts'){
	  			rowsCurr = contactFields
	  			contactsRef.current.style.color = 'rgb(60, 103, 170)'
	  			contactsRef.current.style.fontWeight = '1000'
	  		}
	  		else if(name === 'Company'){
	  			rowsCurr = companyFields
	  			companyRef.current.style.color = 'rgb(60, 103, 170)'
	  			companyRef.current.style.fontWeight = '1000'
	  		}
	  		axiosClient.getRequest(constants.CUSTOM_FIELDS+"?name="+name)
			.then(function (response) {
				let rowsArr = response.data.map((element, index) => (					
					{id:element.id, name: element.name, type: element.type, 
					apLabel: element.apLabel, CP: element.cpLabel, element: element, active: element.active }
				));

				if(rowsArr.length !== 0){
					rowsCurr = rowsCurr.concat(rowsArr)
				}
				setRows(rowsCurr)
				showCreateWindowRef.current.style.display = 'none'
			}).catch(function (response) {
			    console.log(response);
			});
	  }

	function createCustomField(event){
		event.preventDefault();
        let payload = {
        	name: name,
        	apLabel: apLabelRef.current.value,
        	active: !activeRef.current.checked,
            cpLabel:cpLabelRef.current ? cpLabelRef.current.value : 'NA',
            type: fieldTypeRef.current.value, 
            apVisibleWhileCreation: apVisibleWhileCreationRef.current ? apVisibleWhileCreationRef.current.checked : '',
            apMandatorySubmission:apMandatorySubmissionRef.current.checked,
            apEditable:apEditableRef.current.checked,
			apMandatoryStatusChange:apMandatoryStatusChangeRef.current ? apMandatoryStatusChangeRef.current.checked : '',
			apVisibleAfterCreation:apVisibleAfterCreationRef.current ? apVisibleAfterCreationRef.current.checked : '',
			apMandatoryResolution:apMandatoryResolutionRef.current ? apMandatoryResolutionRef.current.checked : '',
			cpMandatory:false,
			cpEditable: false           
        }
        if(cpVisibleRef.current)
        	payload['cpVisible'] = cpVisibleRef.current.checked
        if(cpMandatoryRef.current)
        	payload['cpMandatory'] = cpMandatoryRef.current.checked
        if(cpEditableRef.current)
        	payload['cpEditable'] = cpEditableRef.current.checked
        if(placeholderRef.current)
        	payload['placeholder'] = placeholderRef.current.value
        let values = []
        for(var i= 0; i< valuesRef.current.children.length ;i++){
            values.push(valuesRef.current.children[i].value)
        }
        payload['values'] = values
        if(edit === -1){
	        axiosClient.postRequest(constants.CUSTOM_FIELDS, payload)
				.then(function (response) {
					// let row = {id:response.data.id, name: response.data.name, type: response.data.type, 
					// 	apLabel: response.data.apLabel, CP: response.data.cpLabel,element: response.data }
					// 	setRows([...rows, row])
					// 	hideCreateWindow()
					loadCustomFields()					
				}).catch(function (response) {
				    console.log(response);
				});
		}else{
			axiosClient.patchRequest(constants.CUSTOM_FIELDS+edit, payload)
			.then(function (response) {
					// let row = {id:response.data.id, name: response.data.name, type: response.data.type, 
					// apLabel: response.data.apLabel, CP: response.data.cpLabel,element: response.data }
					// let tableRowsArr = rows;
					// tableRowsArr[edit] = row
					// setRows(tableRowsArr)
					// hideCreateWindow()
					loadCustomFields()
			}).catch(function (response) {
			    console.log(response);
			});
		}
	}

	function addBoxes(){
        let input = document.createElement('input')
        input.style.width = "85%"
        input.style.marginLeft = "5%"
        input.style.marginTop = "14px"
        input.required = true
        input.name='somename'
        valuesRef.current.appendChild(input)
    }
    function removeBoxes(){
        if(valuesRef.current.children.length <= 1)
            return
        valuesRef.current.removeChild(valuesRef.current.children[valuesRef.current.children.length-1])
    }


	
	function displayCustomField(element){
		console.log('displayCustomField '+JSON.stringify(element))
		showCreateWindowRef.current.style.display = 'block'
		fieldTypeRef.current.value=element.type
		apLabelRef.current.value = element.apLabel
		if(cpLabelRef.current)
        	cpLabelRef.current.value = element.cpLabel
        if(element.type === "textbox" || element.type === "number" || element.type === "email" || element.type === "date" || element.type === "datetime" || element.type === "decimal" || element.type === 'checkbox' || element.type === 'textarea'){
        	console.log('clearing values')
        	element.values = ''
        }
        		
		fieldTypeRef.current.value = element.type		
		if(apVisibleWhileCreationRef.current)
        	apVisibleWhileCreationRef.current.checked = (element.apVisibleWhileCreation  === 'true')
        if(apMandatorySubmissionRef.current)
        	apMandatorySubmissionRef.current.checked = (element.apMandatorySubmission  === 'true')
        if(apEditableRef.current)
        	apEditableRef.current.checked = (element.apEditable  === 'true')
        if(apMandatoryStatusChangeRef.current)
			apMandatoryStatusChangeRef.current.checked =  (element.apMandatoryStatusChange  === 'true')
		if(apVisibleAfterCreationRef.current)
			apVisibleAfterCreationRef.current.checked =  (element.apVisibleAfterCreation  === 'true')
		if(apMandatoryResolutionRef.current)
			apMandatoryResolutionRef.current.checked = (element.apMandatoryResolution  === 'true')
		if(cpVisibleRef.current)
			cpVisibleRef.current.checked = (element.cpVisible  === 'true')
		if(cpMandatoryRef.current)
			cpMandatoryRef.current.checked = (element.cpMandatory  === 'true')
		if(cpEditableRef.current)
			cpEditableRef.current.checked = (element.cpEditable === 'true')
		if(activeRef.current)
			activeRef.current.checked = !element.active
		if(placeholderRef.current)
			placeholderRef.current.value = element.placeholder
		console.log('element values '+element.values)
		if(element.values !== ''){
			let values = element.values.split(";")
			valuesRef.current.children[0].value = values[0]
			for(var i=1;i<values.length;i++){
				addBoxes()
				valuesRef.current.children[i].value = values[i]
			}
			groupRef.current.style.display = 'block'
		}
		setEdit(element.id)
	}
	function displayCreateWindow(){
		showCreateWindowRef.current.style.display = 'block'
		fieldTypeRef.current.value = 'textbox'
		setEdit(-1)
	}
	function hideCreateWindow(){
		fieldTypeRef.current.value= ''
		apLabelRef.current.value = ''
		if(cpLabelRef.current)
        	cpLabelRef.current.value = ''
		fieldTypeRef.current.value = ''
		if(apVisibleWhileCreationRef.current)
        	apVisibleWhileCreationRef.current.checked = false
        if(apEditableRef.current)
        	apEditableRef.current.checked = false
        if(apMandatorySubmissionRef.current)
        	apMandatorySubmissionRef.current.checked = false
        if(apMandatoryStatusChangeRef.current)
			apMandatoryStatusChangeRef.current.checked = false
		if(apVisibleAfterCreationRef.current)
			apVisibleAfterCreationRef.current.checked =  false
		if(apMandatoryResolutionRef.current)
			apMandatoryResolutionRef.current.checked = false
		if(cpVisibleRef.current)
			cpVisibleRef.current.checked = false
		if(cpMandatoryRef.current)
			cpMandatoryRef.current.checked = false
		if(cpEditableRef.current)
			cpEditableRef.current.checked = false
		if(placeholderRef.current)
			placeholderRef.current.value = ''
		while(valuesRef.current.children.length > 1){            
        	valuesRef.current.removeChild(valuesRef.current.children[valuesRef.current.children.length-1])
        	groupRef.current.style.display = 'none'        	
        }
        valuesRef.current.children[0].value = ''
		showCreateWindowRef.current.style.display = 'none'
		setEdit(-1)
	}

	function fieldTypeSelected(event){			
		if(["checkbox-group", "radio","dropdown", "dropdown-multi"].includes(event.target.value)){
			groupRef.current.style.display='block'
			valuesRef.current.children[0].required = true
		}	
		else{
			groupRef.current.style.display='none'
			while(valuesRef.current.children.length > 1){            
	        	valuesRef.current.removeChild(valuesRef.current.children[valuesRef.current.children.length-1])   	
	        }
	        valuesRef.current.children[0].value = ''
			valuesRef.current.children[0].required = false
		}
		if(["textbox", "textarea"].includes(event.target.value)){
			setDisplayPlaceholder(true)
		}else
			setDisplayPlaceholder(false)
	}

	function performAction(type){
			let payload={ids: ids}
			axiosClient.postRequest(constants.CUSTOM_FIELDS+"delete/",payload)
			.then(function (response) {
				setIds([])
				loadCustomFields()
			}).catch(function (response) {
			    console.log(response);
			});
	}

function onPrimaryCheckboxClicked(event){
	let idsTemp = ids;
	let checkBoxes = document.getElementsByClassName('custom-field-checkbox')
	for(var i=0;i<checkBoxes.length;i++){
		checkBoxes[i].checked = event.target.checked
		let id = checkBoxes[i].name.split('-')[1]
		if(event.target.checked && !idsTemp.includes(id)){
			idsTemp.push(id)
			groupButtonsRef.current.style.visibility = 'visible'				
		}
	}
	if(!event.target.checked){	
		groupButtonsRef.current.style.visibility = 'hidden'
		idsTemp.splice(0,idsTemp.length)
	}
	setIds(idsTemp)

}

function checkBoxClicked(id, value){
	let idsTemp = ids;
	if(value && !idsTemp.includes(id)){
		idsTemp.push(id)
	}else{
		 const index = idsTemp.indexOf(id);
		if (index > -1) {
  			idsTemp.splice(index, 1);
		}
	}
	setIds(idsTemp)
	if(idsTemp.length !== 0){		
		groupButtonsRef.current.style.display = 'inline'
	}else{
		groupButtonsRef.current.style.display = 'none'
	}
	// if(!value){
	// 	primaryCheckBoxRef.current.checked = false
	// }
	console.log('checkBoxClicked '+ idsTemp)
}

 
	return (
	<>
		<div class = 'header-body'>
		  <div class="main-header">
			<div style={{display:'inline-block', float:'left',textAlign:'baseline'}}><b>Knowledge Base</b></div>
			<div style={{display:'inline-block', right:'0px',position:'absolute'}}>
			   <div class="header-right" style={{display:'inline'}} ref={groupButtonsRef}>
	             <button class='ss-button'  type="button"  onClick={() => performAction('delete')}>Delete</button>
	           </div>
				<button class='ss-button' onClick={displayCreateWindow}>+ New Field </button>
			</div>
	      </div>
		</div>
		<div class='main-body'>				
			<div style={{padding:'10px',position:'absolute', left:'0px',width:'250px', borderRight:'1px solid #dee2e6',minWidth:'269px', height:'100%',overflowY:'auto', paddingLeft:'20px', backgroundColor:'rgb(244, 244, 244)'}} >
				
				<div>
					<div style={{borderRadius:'5px',marginTop:'20px', padingLeft:'20px', padding:'5px', cursor: 'pointer'}}>
						<a href='#' style={{display:'inline-block',width:'70%',fontSize:'16px',fontWeight:'600',color:'black',textDecoration:'none'}} onClick={()=>setName('Tickets')} ref={ticketsRef}>Ticket Fields</a>
					</div>
					<div style={{borderRadius:'5px',marginTop:'20px',  padingLeft:'20px', padding:'5px', cursor: 'pointer'}}>
						<a href='#' style={{display:'inline-block',width:'70%',fontSize:'14px',fontWeight:'800',color:'#808080',textDecoration:'none'}} onClick={()=>setName('Agents')} ref={agentsRef}>Agent Fields</a>
					</div>
					<div style={{borderRadius:'5px',marginTop:'20px',  padingLeft:'20px', padding:'5px', cursor: 'pointer'}}>
						<a href='#' style={{display:'inline-block',width:'70%',fontSize:'14px',fontWeight:'800',color:'#808080',textDecoration:'none'}} onClick={()=>setName('Contacts')} ref={contactsRef}>Contact Fields</a>
					</div>
					<div style={{borderRadius:'5px',marginTop:'20px',  padingLeft:'20px', padding:'5px', cursor: 'pointer'}}>
						<a href='#' style={{display:'inline-block',width:'70%',fontSize:'14px',fontWeight:'800',color:'#808080',textDecoration:'none'}} onClick={()=>setName('Company')} ref={companyRef}>Company Fields</a>
					</div>
				</div>
			</div>
			
			<DraggableTable rows={rows} displayCustomField={displayCustomField} name={name} checkBoxClicked={checkBoxClicked}/>								


			<div style={{display:'none'}} ref={showCreateWindowRef}>
	        <div class="create-wrapper" style={{backgroundColor: '##f7f7f7'}}>      
	              <form onSubmit={createCustomField}>          
	                  <div style={{padding:'30px', paddingleft:'0px',backgroundColor:'white',height: 'calc(100vh - 105px', overflowY: 'auto'}}>
	                      <a href="javascript:void(0)" class="create-closebtn" onClick={hideCreateWindow}>&times;</a>
	                      <div class='create-form-label' style={{ marginTop: "3%"}}>Field Type:</div>
	                      <div>
	                      	<select  name="fieldType" style={{width: "90%", paddingLeft:'5px',height:'33px',borderRadius:'7px'}}  ref={fieldTypeRef} onChange={fieldTypeSelected} >
	                      		<option value='textbox'>Single Line Text Box</option>
	                      		<option value='textarea'>Multi Line Text Box</option>
	                      		<option value='dropdown'>Dropdown</option>
	                      		<option value='dropdown-multi'>Multi Select Dropdown</option>
	                      		<option value='checkbox'>Check Box</option>
	                      		<option value='checkbox-group'>Check Boxes Group</option>
	                      		<option value='radio'>Radio Button Group</option>
	                      		<option value='date'>Date Field</option>
	                      		<option value='datetime'>Date Time Field</option>
	                      		<option value='number'>Numberic</option>
	                      		<option value='decimal'>Decimal</option>
	                      	</select>
	                      </div>
	                      <div class='create-form-label' style={{ marginTop: "3%"}}>Label on Agent Portal:<p class='red-color'>*</p></div>
	                      <div><input type="text" name="agentPortalName" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Label on Agent Portal'} required  maxlength='500' ref={apLabelRef} /></div>
	                    { (name === 'Tickets') ? 
	                     	<>  
	                      <div style={{ marginTop: "3%"}} class='create-form-label'>Label on Customer Portal:<p class='red-color'>*</p></div>
	                      <div><input  type="text" name="customerPortalName"  style={{width: "90%", outline: 'none', paddingLeft:'5px' }}     placeholder={'Label on Customer Portal'}  maxlength='320'  required ref={cpLabelRef} /> </div>
	                      </> : ""
	                    }
	                    { displayPlaceholder &&
	                      <>	
	                      	<div class='create-form-label' style={{ marginTop: "3%"}}>Placeholder:</div>
	                      	<div><input type="text" name="placeholder" style={{width: "90%", paddingLeft:'5px'}} placeholder={'Placeholder'}   maxlength='500' ref={placeholderRef} /></div>
	                      </>
	                    }

	                      <div style={{marginTop:'3%', display:'none'}} ref={groupRef}>
	                        	<div class='create-form-label'>Values:</div>
	                        	<div ref={valuesRef}>                      
	                            	<input  style={{marginLeft:'5%', width:'85%', display:'inline',marginRight:'20px'}}   /> 
	                            </div>
	                         <div style={{marginTop:'20px'}}><a href='#' onClick={addBoxes}>Add  Values</a> <a href='#' style={{float:'right'}} onClick={removeBoxes}>Remove  Values</a></div>                       
	                      </div>

	                   <input type='checkbox' style={{width:'13px', height:'13px',marginTop:'15px',marginRight:'10px' }}  ref={activeRef}/> Disable
	                   { (name === 'Tickets') ? 
	                     	<>   
	                      <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
	                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={apVisibleWhileCreationRef} /> &nbsp; Field is Visible  in Agent Portal </div>
	                      
	                      <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={apMandatorySubmissionRef} /> &nbsp; Mandatory value </div>
	                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={apEditableRef} /> &nbsp;  Editable  After  Ticket Creation</div>
	                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentView"  style={{width: "14px", outline: 'none' }} ref={apVisibleAfterCreationRef} /> &nbsp;  Visible to agents after Ticket Creation  </div>	                      
	                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredStatusChange"  style={{width: "14px", outline: 'none' }} ref={apMandatoryStatusChangeRef} />&nbsp;   Mandatory for Ticket Status Change  </div>
	                      <div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="requiredResolution"  style={{width: "14px", outline: 'none' }} ref={apMandatoryResolutionRef} />&nbsp;   Mandatory for Ticket Resolution  </div>
	                     
		                      <div class='create-form-label' style={{ marginTop: "3%"}}>Customer Portal</div>
		                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox"  style={{width: "14px", outline: 'none' }}  ref={cpVisibleRef} />&nbsp; Field is Visible  in Customer Portal  </div>                      
		                      <div style={{ marginTop: "5px",marginLeft:'50px'}}><input  type="checkbox"  style={{width: "14px", outline: 'none' }} ref={cpMandatoryRef} />&nbsp; Mandatory Value  </div>                      
		                    </>
		                 	: 
		                  <>
		                  	    <div class='create-form-label' style={{ marginTop: "3%"}}>Agent Portal</div>
		                  	    <div style={{ marginTop: "5px",marginLeft:'50px'}} ><input  type="checkbox" name="aRequired"  style={{width: "14px", outline: 'none' }} ref={apMandatorySubmissionRef} /> &nbsp; Mandatory Field </div>
		                      	<div style={{ marginTop: "10px",marginLeft:'50px'}} ><input  type="checkbox" name="agentEdit"  style={{width: "14px", outline: 'none' }} ref={apEditableRef} /> &nbsp;  Editable  After Creation</div>	                      		                      	
		                  </>
		             	 }
	                    </div>  
	                    <div style={{position:'absolute',bottom:'0px',height:'75px',width:'100%'}}>
	                      <button class='ss-button'  class='create-button' style={{marginLeft:'30%'}} >{ (edit === -1) ? 'Create Custom Field' : 'Update Custom Field' }</button>                      
	                    </div>            
	                  </form>                             
	          </div>
	        </div>
    	</div> 

		{/*<div>
		      <Select
		        isMulti
		        value={selectedOptions}
		        onChange={handleChange}
		        options={options}
		        placeholder="Select options"
		      />
		      <div>
		        <h4>Selected Options:</h4>
		        {selectedOptions && selectedOptions.map((option) => (
		          <p key={option.value}>{option.label}</p>
		        ))}
		      </div>
    	</div>
    	*/}
    	
    </>
	)
}

export default CustomFieldsList;