import React, { useState, useEffect } from 'react';
import * as constants from '../../../js/constants'
import * as axiosClient from '../../../js/Axios';
import {ToastContainer, toast } from 'react-toastify';
const ApiInputComponent = ({ closeCreateWindow, editApiRef }) => {
  const [apiUrl, setApiUrl] = useState('');
  const [httpMethod, setHttpMethod] = useState('GET');
  const [headers, setHeaders] = useState([{ key: '', value: '' }]);
  const [body, setBody] = useState('');
  const [apiResponse, setApiResponse] = useState('');
  const [error, setError] = useState('');
  const [name, setName] = useState('')
  const [variables, setVariables] = useState([{ key: '', value: '' }]);



  useEffect(()=>{
    if(editApiRef.current){
        axiosClient.getRequest(constants.CHAT_BOT_API+'/'+editApiRef.current)
       .then(function (response) {
            setName(response.data.name)
            setApiUrl(response.data.url || '');
            setHttpMethod(response.data.httpMethod || 'GET');
            setBody(response.data.body ? response.data.body.replaceAll("ss_new_line","\n").replaceAll("ss_double_quotes", "\""): "")
            // setAuthType(response.data.authType || '');
            // setDescription(response.data.description || '');
            console.log('parseKeyValuePairs '+JSON.stringify(parseKeyValuePairs(response.data.headers)))
            setHeaders(parseKeyValuePairs(response.data.headers));
            setVariables(parseKeyValuePairs(response.data.variables));
      }).catch((error) => {
             console.log(error)
      });
    }
  },[])

function parseKeyValuePairs(input) {
    return input.split(";").map(pair => {
        let [key, value] = pair.trim().split(":");
        return { key: key, value: value };
    });
}
  // Handle adding headers
  const handleAddHeader = () => {
    setHeaders([...headers, { key: '', value: '' }]);
  };

  // Handle updating header key/value pairs
  const handleHeaderChange = (index, field, value) => {
    const newHeaders = [...headers];
    newHeaders[index][field] = value;
    setHeaders(newHeaders);
  };

  // Handle removing headers
  const handleRemoveHeader = (index) => {
    const newHeaders = headers.filter((_, i) => i !== index);
    setHeaders(newHeaders);
  };


  const handleAddVariable = () => {
    console.log('handleAddVariable '+JSON.stringify(variables))
    setVariables([...variables, { key: '', value: '' }]);
  };

  // Handle updating header key/value pairs
  const handleVariableChange = (index, field, value) => {
    const newVariables = [...variables];
    newVariables[index][field] = value;
    setVariables(newVariables);
  };

  // Handle removing headers
  const handleRemoveVariable = (index) => {
    const newVariables = variables.filter((_, i) => i !== index);
    setVariables(newVariables);
  };

  // Validate JSON format for the request body
  const validateJson = (json) => {
    try {
      JSON.parse(json);
      return true;
    } catch {
      return false;
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    console.log('handleSubmit')
    e.preventDefault();

    // Validate body for POST or PUT requests
    // if ((httpMethod === 'POST' || httpMethod === 'PUT') && body && !validateJson(body)) {
    //   setError('Invalid JSON format in body.');
    //   return;
    // }

    const apiData = {
      url: apiUrl,
      httpMethod: httpMethod,
      headers: headers.filter(header => header.key && header.value),
      variables: variables.filter(variable => variable.key && variable.value),
      body: httpMethod !== 'GET' ? body : null,
    };

    // Create the payload for the API request
    let payload = {
      name:name,
      url:apiUrl,
      httpMethod: httpMethod,
      body: body ? body: "",
      headers: apiData.headers.length > 0 ? 
        apiData.headers.reduce((acc, header) => {
          acc[header.key] = header.value;
          return acc;
        }, {}) : {},
      variables: apiData.variables.length > 0 ? 
              apiData.variables.reduce((acc, variable) => {
                acc[variable.key] = variable.value;
                return acc;
              }, {}) : {},
    };

    if(editApiRef.current){
      axiosClient.patchRequest(`${constants.CHAT_BOT_API}/${editApiRef.current}`, payload)
          .then((response) => {
              console.log("API details updated successfully", response.data);
              toast.success("API details updated successfully")
              // Optional: You could add success notifications or reset form here if needed.
          })
          .catch((error) => {
              console.log("Error updating API data:", error);
          });
    }else{
      axiosClient.postRequest(constants.CHAT_BOT_API, payload)
         .then(function (response) {
          console.log('response '+response.data)
          toast.success("API details created successfully")
          closeCreateWindow(true)
      }).catch((error) => {
             console.log(error)
      });
    }
  };


const checkResponse = async (e) => {
  e.preventDefault();
  console.log('checkResponse '+ (body === ""));

  try {
    const response = await fetch(apiUrl, {
      method: httpMethod,
      headers: {
        "Content-Type": "application/json",  // ✅ Set first to avoid conflicts
        ...headers.reduce((acc, header) => {
          if (header.key && header.value) acc[header.key] = header.value;
          return acc;
        }, {}),
      },
      body: httpMethod !== "GET" && body ? JSON.stringify(JSON.parse(body)) : undefined,
      credentials: "include"
    });

    const result = await response.text();
    setApiResponse(JSON.stringify({"response":JSON.parse(result)}));
    setError('');
  } catch (err) {
    setError(`Error: ${err.message}`);
    setApiResponse('');
  }
};



  return (
   <div className="create-wrapper">      
  <h4>Configure API Request</h4>
  <a href="javascript:void(0)" className="create-closebtn" onClick={closeCreateWindow}>&times;</a>
  <div className="create-line"></div> 
  <form onSubmit={handleSubmit}>
    <div>
      <div className="create-form-label" style={{ marginTop: "30px" }}>Name:</div>
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="name"
        required
        style={{ width: "90%" }}
      />
    </div>      
    
    <div>
      <div className="create-form-label" style={{ marginTop: "30px" }}>API Endpoint:</div>
      <input
        type="url"
        value={apiUrl}
        onChange={(e) => setApiUrl(e.target.value)}
        placeholder="https://example.com/api"
        required
        style={{ width: "90%" }}
      />
    </div>

    <div style={{ marginTop: "20px" }}>
      <span className="create-form-label">HTTP Method:</span>
      <select value={httpMethod} onChange={(e) => setHttpMethod(e.target.value)} style={{ marginLeft: '10px', width: '100px', borderRadius: '5px', padding: '5px 8px' }}>
        <option value="GET">GET</option>
        <option value="POST">POST</option>
        <option value="PATCH">PATCH</option>
        <option value="PUT">PUT</option>
        <option value="DELETE">DELETE</option>
      </select>
    </div>

    <div style={{ marginTop: "20px" }}>
      <div className="create-form-label">Headers:</div>
      {headers.map((header, index) => (
        <div key={index} style={{ display: 'flex', alignItems: 'center' }}>
          <input
            type="text"
            value={header.key}
            placeholder="Header Key"
            onChange={(e) => handleHeaderChange(index, 'key', e.target.value)}
            style={{ width: '40%', marginTop: '10px' }}
          />
          <input
            type="text"
            value={header.value}
            placeholder="Header Value"
            onChange={(e) => handleHeaderChange(index, 'value', e.target.value)}
            style={{ width: '40%', marginLeft: '10px', marginTop: '10px' }}
          />
          <button type="button" onClick={() => handleRemoveHeader(index)} className="ss-button" style={{ marginLeft: '10px', marginTop: '10px' }}>
            x
          </button>
        </div>
      ))}
      <button type="button" onClick={handleAddHeader} className="ss-button" style={{ marginTop: "10px" }}>Add Header</button>
    </div>

    {(httpMethod !== 'GET') && (
      <div style={{ marginTop: "20px" }}>
        <div className="create-form-label">Body (JSON format):</div>
        <textarea
          value={body}
          onChange={(e) => setBody(e.target.value)}
          placeholder='{"key": "value"}'
          style={{ width: '90%', height: '150px' }}
        />
      </div>
    )}

    <button className="ss-button" style={{ marginTop: '20px' }} onClick={checkResponse}>Check API Response</button>

    {/* Display error messages */}
    {error && <div style={{ color: 'red', marginTop: '10px' }}>{error}</div>}

    {/* Display API response */}
    <div style={{ marginTop: "20px" }}>
      <div className="create-form-label">API Response:</div>
      <textarea
        value={apiResponse}
        readOnly
        placeholder="API response will appear here..."
        style={{ width: '90%', height: '150px', backgroundColor: '#f5f5f5', padding: '10px' }}
      />
    </div>

    {/* Add Variables Button */}
    <button type="button" className="ss-button" style={{ marginTop: "20px" }} onClick={handleAddVariable}>
      Add Variables
    </button>

    {/* Variable Mapping Section */}
    {variables.length > 0 && (
      <div style={{ marginTop: "20px" }}>
        <div className="create-form-label">Map API Response Variables:</div>
        {variables.map((variable, index) => (
          <div key={index} style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
            <input
              type="text"
              placeholder="Response Key"
              value={variable.key}
              onChange={(e) => handleVariableChange(index, "key", e.target.value)}
              style={{ width: "40%" }}
            />
            <input
              type="text"
              placeholder="Variable Name"
              value={variable.value}
              onChange={(e) => handleVariableChange(index, "value", e.target.value)}
              style={{ width: "40%", marginLeft: "10px" }}
            />
            <button
              type="button"
              onClick={() => handleRemoveVariable(index)}
              className="ss-button"
              style={{ marginLeft: "10px" }}
            >
              x
            </button>
          </div>
        ))}
        <button type="button" onClick={handleAddVariable} className="ss-button" style={{ marginTop: "10px" }}>
          Add More Variables
        </button>
      </div>
    )}

    <button type="submit" className="ss-button" style={{ marginTop: '20px' }}>
      {editApiRef.current ? 'Update API' : 'Create API'}
    </button>
  </form>

  <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>
</div>

  );
};

export default ApiInputComponent;
