import React, { useState, useRef, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import SLACondition from './SLACondition';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants';

function CreateSLAForm() {
  const nameTextboxRef = useRef(null);
  const businessHoursRef = useRef(null);
  const errorRef = useRef(null);
  const navigate = useNavigate();
  const params = useParams();

  const [conditions, setConditions] = useState([]);
  const [businessHoursId, setBusinessHoursId] = useState(-1);

  useEffect(() => {
  	// errorRef.current.style.display = 'none'
    if (params.id) {
      populateCreateForm();
    }
  }, [params.id]);

  // const populateCreateForm = () => {
  //   axiosClient.getRequest(constants.SLA_URL_REQ + params.id)
  //     .then(response => {
  //       nameTextboxRef.current.value = response.data.name;
  //       // Populate business hours, conditions, and more from response
  //       setBusinessHoursId(response.data.businessCalender?.id || -1);

  //       const populatedConditions = response.data.conditions.map((condition, index) => ({
  //         index,
  //         ...condition
  //       }));
  //       setConditions(populatedConditions);
  //     })
  //     .catch(error => console.error('Error fetching SLA data:', error));
  // };

	function populateCreateForm(){
		console.log('populatedCreateForm')
		axiosClient.getRequest(constants.SLA_URL_REQ+params.id)
			.then(function (response) {
			  nameTextboxRef.current.value = response.data.name
			  businessHoursRef.current.value = response.data.businessCalender !== null ? response.data.businessCalender.name : ''
			  setBusinessHoursId(response.data.businessCalender !== null ? response.data.businessCalender.id : -1)
			  // descriptionRef.current.value = (response.data.message === 'null' ? '' : response.data.message)
			  	// fill priorities and responses
			  	let elements = document.getElementsByClassName('time-input')
				for(var i=0;i<elements.length; i++){
					if(elements[i].name === '')
						continue
					 let arr = elements[i].name.split('-');					 
					 if(arr.length === 3 &&
						 ['critical', 'medium', 'low', 'high'].includes(arr[0])){
						 	//ciritcal-first-days
								if(arr[1] === 'first')
									elements[i].value = response.data[arr[0]]['firstResponse'+arr[2]]
								else if(arr[1] === 'second')
									elements[i].value = response.data[arr[0]]['resolution'+arr[2]]
								else if(arr[1] === 'remind')
									elements[i].value = response.data[arr[0]]['remind'+arr[2]]
								else if(arr[1] === 'escalate')
									elements[i].value = response.data[arr[0]]['escalate'+arr[2]]
					}
				}	
				var checkedBoxes = document.querySelectorAll('input[type=checkbox]');			
				for(var i=0;i<checkedBoxes.length;i++){
					let arr = checkedBoxes[i].name.split('-')				
					checkedBoxes[i].checked = (response.data[arr[0]][(arr[1]+'-'+arr[2])] === true)
				}
		        const populatedConditions = response.data.conditions.map((condition, index) => ({
		          index,
		          ...condition
		        }));
		        setConditions(populatedConditions);
			 //  if(populatedConditions.length > 0)
				// removeRouteLink.current.style.visibility = 'visible'
			}).catch(function (error) {
				console.log(error)
				// window.location.href = constants.SLA_URL_ABS
			});
	}
  const addCondition = () => {
      setConditions([...conditions, { index: conditions.length, firstSelect: 'Created By', fromSelection: '', inputValue: '', fromValue: '' }]);
  };

  const removeCondition = (e, id) => {
  	e.preventDefault()
    let conditionsTemp  = conditions    
  	setConditions([])
  	conditionsTemp = conditionsTemp.filter((_, index) => index !== id)
  	setConditions(conditionsTemp)
  };

  const handleConditionChange = (index, updatedCondition) => {
    const newConditions = conditions.map((condition, i) => i === index ? updatedCondition : condition);
    setConditions(newConditions);
  };

  const validateForm = () => {
    if (businessHoursId === -1) {
      displayError('Please select business hours');
      return false;
    }

    console.log('validateForm '+ JSON.stringify(conditions))
    let index = 1;
    for (let condition of conditions) {
    	console.log('inside '+condition.fromValue+'   '+condition.index+'     ')
      if ( !['Channel', 'Type','Email Ticketing', 'Agent Portal', 'Customer Portal', 'Call', 'Chat', 'Any','AnyOne','Incident', 'Service Request', 'Change Request', 'Payment', 'Escalation'].includes(condition.fromSelection)  &&  ! condition.fromValue) {
        displayError(`Please select a valid value for condition #${index }`);
        return false;
      }
      index++
    }

    return true;
  };

  function handleSubmit(event){
	event.preventDefault();
	if(!validateForm(event)){
		console.log("validateForm is wrong")
		return
	}	
	let payload = { 
					name: nameTextboxRef.current.value, 'critical':{},
					priority: event.target.priority.value,businessCalenderId: businessHoursId,
					'high':{},'medium':{},'low':{}, 
					conditions: conditions.map(c => ({
						firstSelect: c.firstSelect,
        				fromSelection: c.fromSelection,
        				fromValue: c.fromValue}))
 				  }
	for(var i=0;i<event.target.length; i++){
		if(event.target[i] === nameTextboxRef.current ){
			continue;
		}
		if(event.target[i].name === '')
			continue
		 let arr = event.target[i].name.split('-');
		 if(arr.length !== 3 || 
		 		!['critical', 'medium', 'low', 'high'].includes(arr[0])){
		 	console.log('name is '+['critical', 'medium', 'low', 'high'].includes(arr[0]))
		 	continue
		}
		 if(arr[1] === 'first')
		 	payload[arr[0]]['firstResponse'+arr[2]] = event.target[i].value
		 else if(arr[1] === 'second')
		 	payload[arr[0]]['resolution'+arr[2]] = event.target[i].value
		 else if(arr[1] === 'remind'){
		 	payload[arr[0]]['remind'+arr[2]] = event.target[i].value
		 }
		 else if(arr[1] === 'escalate'){
		 	payload[arr[0]]['escalate'+arr[2]] = event.target[i].value
		 }
	}


	if(params.id === undefined){
		axiosClient.postRequest(constants.SLA_URL_REQ, payload)
			.then(function (response) {
				navigate(constants.SLA_URL_ABS)
			}).catch(function (error) {
				console.log(error)
			    errorRef.current.innerHTML = error.response.data.error
			    errorRef.current.style.display = 'block'
			});
	}else{
			axiosClient.patchRequest(constants.SLA_URL_REQ+params.id, payload)
					.then(function (response) {
					navigate(constants.SLA_URL_ABS)
				}).catch(function (error) {
					if(error.response.data === undefined)
				    	errorRef.current.innerHTML = '<span style="color: red">* </span>Unknown error occurred. Please try again after sometime'
				    else
				    	errorRef.current.innerHTML = '<span style="color: red">* </span>'+error.response.data.error
				    errorRef.current.style.display = 'block'
				});
		}
	console.log(payload)
}
function handleTimeInput(event, type){
	event.target.setCustomValidity('')
	let minutes = ['0','1','2','3','4','5','6','7','8','9',
		'00','01','02','03','04','05','06','07','08','09',]
	    for(var i = 10;i<= 59;i++){
	      minutes.push(String(i))
	    }
	let hours = ['0','1','2','3','4','5','6','7','8','9',
				 '00','01','02','03','04','05','06','07','08','09',
                 '10','11','12','13','14','15','16','17','18','19', '20',
        		 '21', '22', '23']
	if(isNaN(event.target.value)){
		event.target.value = event.target.value.slice(0, -1);	
		return
	}
	if(type==='hours' && !hours.includes(event.target.value)){
		event.target.setCustomValidity('Valid Input: 0 to 23')
	}
	if(type==='minutes' && !minutes.includes(event.target.value)){
		event.target.setCustomValidity('Valid Input: 1 to 59')
	}	
} 
function cancelCreate(){
	navigate(constants.SLA_URL_ABS)
}

  function onSelectDropdown(event){
      event.target.parentElement.parentElement.children[0].value = event.target.innerHTML      
      event.target.parentElement.parentElement.children[1].style.display = 'none'
      setBusinessHoursId(event.target.name)
  }
  function addChildToDropdown(element, event){
      let aTag = document.createElement('a')
      aTag.name=element.id
      aTag.innerHTML = element.name
      aTag.onclick = onSelectDropdown
      event.target.parentElement.children[1].appendChild(aTag)
      event.target.parentElement.children[1].style.display = 'block'
  } 
function handleBusinessHours(event){
	  event.target.setCustomValidity('');
      event.target.parentElement.children[1].innerHTML = ''      
      setBusinessHoursId(-1)
      if(event.target.value.length < 3)
        return
      axiosClient.getRequest(constants.BUSINESS_CALENDER_URL+"search_by_name?keyword="+event.target.value)
      .then(function (response) {
          response.data.forEach((element, index)=>{             
              addChildToDropdown(element,event)
          })
          event.target.parentElement.children[1].style.display = 'block'
      }).catch(function (response) {
          console.log(response);
      });
} 
function redirectToSLAList(){
  navigate(constants.SLA_URL_ABS)
}

function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red;font-weight:500'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}


  return (
  <>
  	<div class='header-body' style={{backgroundColor: 'white'}}>
	 		<a href='' style={{marginLeft:'20px', marginTop:'10px', textDecoration: 'none', fontSize:'16px'}} onClick={redirectToSLAList}> > All SLAs</a>
	 </div>
    <div class='main-body'>
    	<div class='container' style={{height:'calc(100% - 75px)', overflowY:'auto'}}>
			<div class="row" style={{marginTop: '3%',minWidth: '750px'}}> 
				<div class='col-sm-12 col-md-9 col-lg-12'>
			      {/* SLA Form Fields */}
			      <form onSubmit={handleSubmit}>
			      	<h8 style={{color:'#044caf',fontWeight:'1000',fontSize:'18px'}}>Configure SLA</h8>  
	            	<div class='create-line'></div>     
	            	<div ref={errorRef} />
				   <div style={{marginTop:'10px', fontWeight:'600', fontSize:'16px',marginLeft:'10px'}}>SLA Name: </div>
				   		<div style={{marginLeft:'20px'}}><input ref={nameTextboxRef} name='name' required type='text' style={{height:'33px', border: '1px solid #aaa', borderRadius: '5px',width:'70%',minWidth:'750px', fontSize:'12px'}} placeholder={'Enter SLA name'}/></div>
				   <div style={{fontWeight:'600', fontSize:'16px',marginTop:'10px',marginLeft:'10px'}}>Priority: </div>
				   		<div style={{marginLeft:'20px'}}>	<select  name='priority' style={{height:'33px', border: '1px solid #aaa', borderRadius: '5px',width:'70%',minWidth:'750px'}} >
				   			<option>1</option>
				   			<option>2</option>
				   			<option>3</option>
				   			<option>4</option>
				   			<option>5</option>
				   			<option>6</option>
				   			<option>7</option>
				   			<option>8</option>
				   			<option>9</option>
				   			<option>10</option>
				   		</select>	
				   </div>
				   <div style={{width:'70%'}}>
				   <table class= 'table' style={{marginTop: '3%',minWidth: '750px'}}>
				 		<thead>
				 		  <tr>
					 		<th style={{width:'100px'}}>&nbsp;</th>
					 		<th style={{paddingLeft:'100px', textAlign: 'left'}}>First Response Within:</th>
					 		<th style={{paddingLeft:'100px',textAlign: 'left'}}>Resolution Within:</th>
					 	  </tr>
					 	</thead>
					 	<tbody>
					 		<tr style={{borderBottom: '1px solid #F5F5F5'}}>
						 		 <td style={{fontWeight:'600' }} >Critical</td>
						 		 <td style={{textAlign:'left'}}>
									 <input type="text" name='critical-first-days' style={{fontSize:'12px', marginLeft:'50px' }} class='time-input' maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
									 <input type="text" name='critical-first-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
									 <input type="text" name='critical-first-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>				 		 
						 		 </td>
						 		 <td style={{textAlign:'left'}}>
									 <input type="text" name='critical-second-days'style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
									 <input type="text" name='critical-second-hours' style={{marginLeft:'5px' }} class='time-input' maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
									 <input type="text" name='critical-second-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>
						 		 </td>
					 		</tr>
					 		<tr>
					 		 <td style={{fontWeight:'600' }}>High</td>
					 		 <td style={{textAlign:'left'}}>
								 <input type="text" name='high-first-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='high-first-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='high-first-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>				 		 
					 		 </td>
					 		 <td style={{textAlign:'left'}}> 
								 <input type="text" name='high-second-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='high-second-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='high-second-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')" /><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>
					 		 </td>
					 		</tr>
					 		<tr>
					 		 <td style={{fontWeight:'600' }}>Medium</td>
					 		 <td style={{textAlign:'left'}}>
								 <input type="text" name='medium-first-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='medium-first-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='medium-first-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>				 		 
					 		 </td>
					 		 <td style={{textAlign:'left'}}>
								 <input type="text" name='medium-second-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='medium-second-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required  onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='medium-second-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required  onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>
					 		 </td>
					 		</tr>
					 		<tr>
					 		 <td style={{fontWeight:'600' }}>Low</td>
					 		 <td style={{textAlign:'left'}}>
								 <input type="text" name='low-first-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='low-first-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='low-first-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>				 		 
					 		 </td>
					 		 <td style={{textAlign:'left'}}>
								 <input type="text" name='low-second-days' style={{marginLeft:'50px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'days')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Days</span>
								 <input type="text" name='low-second-hours' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'hours')}  oninput="setCustomValidity('')" /><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Hours</span>
								 <input type="text" name='low-second-minutes' style={{marginLeft:'5px' }} class='time-input'  maxlength='2' required onChange={(event)=>handleTimeInput(event, 'minutes')}  oninput="setCustomValidity('')"/><span style={{display:'inline-block',paddingLeft:'5px',paddingRight:'5px',border: 'none', fontSize:'10px',height:'25px'}}>Minutes</span>
					 		 </td>
					 		</tr>				 		
					 	</tbody>
				 	</table>
				 	</div>

			        {/* SLA Conditions */}
			        <div style={{width:'70%',minWidth:'750px'}}>
			          {conditions.map((condition, index) => (
			            <SLACondition key={index} index={index} condition={condition} onConditionChange={handleConditionChange} removeCondition={removeCondition}/>
			          ))}
			        </div>

					<div >
						<a href="javascript:void(0)"  onClick={addCondition} style={{float: 'left',marginLeft:'10px',fontSize:'16px'}}>Add Condition</a>
			            <a href="javascript:void(0)" onClick={removeCondition} style={{marginLeft: '400px',fontSize:'16px', display:'none'}}>Remove Condition</a>
			        </div>
					<div class='create-form-label' style={{marginTop: "50px"}}>Business Hours:</div>              
		               <div style={{marginBottom:'10%', position: 'relative'}}>
		                 	<input name="businessHours" style={{width: '70%',minWidth:'750px', height: "33px"}} placeholder={'Business Hours...'} onChange={handleBusinessHours} ref={businessHoursRef}/>
		                 	<div style={{display:'none' }} class='sla-dropdown-content'/>
		               </div>

			          <div>
							<button class='ss-button'  style={{fontWeight:'bolder', fontSize:'14px', paddingLeft:'30px',paddingRight:'30px',position:'absolute',marginLeft:'10%',bottom:'3px'}}  onClick={cancelCreate}>Cancel </button> 
							<button class='ss-button'  style={{fontWeight:'bolder', fontSize:'14px', paddingLeft:'30px',paddingRight:'30px',position:'absolute',marginLeft:'40%',bottom:'3px'}}> {(params.id !== undefined) ? 'Update' : 'Create' } </button>
			          </div>
			      </form>
			      
			 </div>
		  </div>
		</div>
    </div>
  </>
  );
}

export default CreateSLAForm;
