import React from "react"
import {useRef, useState, setState, useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom"
import ReactDOMServer from 'react-dom/server';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import '../../../css/main.css'
import IVRConditionLine from './IVRConditionLine'
import { ToastContainer, toast } from 'react-toastify';

function CreateIVR(props){
	const nameTextboxRef = useRef(null)
	const messageTextboxRef = useRef(null)
	const conditionsRef = useRef(null)
	const [ivrConditionLine, setIvrConditionLine] = useState([])
	const errorRef = useRef(null)
	const addIVRLink = useRef(null)
	const removeIVRLink = useRef(null)
	const navigate = useNavigate(null)
	const params = useParams()
	let fromRefsArr = [];
	let toRefsArr = [];
	let selectedFromValueIdArr = []
	let selectedToValueIdArr = [];

	useEffect(()=>{
		removeIVRLink.current.style.display = 'none'
		errorRef.current.style.display = 'none'
		if(params.id !== undefined)
			fillForm()
		if(params.id === undefined && conditionsRef.current.children.length === 0)
			addConditionsRef()
	},[])

function fillForm(){
		axiosClient.getRequest(constants.CALL_URL+'ivr/'+params.id)
		.then(function (response) {
		  nameTextboxRef.current.value = response.data.name
		  messageTextboxRef.current.value = (response.data.welcomeMessage === 'null' ? '' : response.data.welcomeMessage)
		  let temp = []
		  for(var i=0; i<response.data.payload.length; i++){
		  	temp.push(response.data.payload[i])		  	
		  }
		  setIvrConditionLine(temp)
		  if(temp.length > 0)
			removeIVRLink.current.style.display = 'inline'
		}).catch(function (error) {
			console.log(error)
		});	
}
  function addConditionsRef(){
  		setIvrConditionLine([...ivrConditionLine, 1])
  		removeIVRLink.current.style.display = 'inline'
  		// addIVRLink.current.scrollIntoView();
  }

  function removeConditionsRef(){
         conditionsRef.current.removeChild(conditionsRef.current.lastChild)
         if(conditionsRef.current.children.length <= 1)
            removeIVRLink.current.style.display = 'none'
         if(conditionsRef.current.children.length < 5)
        	addIVRLink.current.style.display = 'inline'
  }	
function redirectToIVRList(event){
	event.preventDefault()
	window.location.href='/admin/ivr'
}
function displayError(msg){
  errorRef.current.innerHTML = "<span style='color:red'>*&nbsp;&nbsp;"+msg+"</span>"
  errorRef.current.style.display='block'
  errorRef.current.scrollIntoView()
}
function validateForm(event){
	let fromErrors = {contact: 'Please search and select a From contact value ',
					  company: 'Please search and select a From company value ',
					  company_group: 'Please search and select a From comapny group value',
					  contact_group: 'Please search and select a From contact group value'}
	let toErrors = {
		agent: 'Please select a To agent value',
		team: 'Please select a To team value'
	}	
	if(event.target.name.value === '')
	{
		displayError('IVR name cannot be empty')
		return false;
	}

	for(var i = 0;i < conditionsRef.current.children.length; i++){
		if(event.target['digit-'+i].value === ''){
				displayError('Please select a digit')
				event.target['digit-'+i].style.border= '1px solid red'
				return false
			}
		if(!event.target['value-'+i].disabled && event.target['value-'+i].value === ''){
				displayError('Please select a value')
				event.target['value-'+i].style.border= '1px solid red'
				return false
		}
		if(!event.target['value-'+i].disabled && event.target[('hidden-'+i)].value === '-1'){
				displayError('Please select a value from dropdown')
				event.target['value-'+i].style.border= '1px solid red'
				return false
		}
	}
	
	return true
}

function handleSubmit(event){
	event.preventDefault()
	errorRef.current.innerHTML = ''
	errorRef.current.style.display = 'none'
	if(!validateForm(event)){
		console.log("validateForm is wrong")
		return
	}
	
	let payload = {
		'name': event.target.name.value,
		'welcomeMessage':event.target.WelcomeMessage.value,
		'payload' : []
	}
	// Children starts with 1 and selected values starts with 0
	for(var i=0;i< conditionsRef.current.children.length;i++){
		payload['payload'].push({'digit' : event.target[('digit-'+i)].value,
									'select' : event.target[('select-'+i)].value,
									// 'value' : event.target[('value-'+i)].value,
									'valueId': event.target[('hidden-'+i)].value
								});
	}

	if(params.id === undefined){
		axiosClient.postRequest(constants.CALL_URL+'ivr', payload)
			.then(function (response) {
					navigate(constants.ADMIN_URL_ABS+'ivr')
			}).catch(function (error) {
				console.log(error)
			    errorRef.current.innerHTML = error.response.data.error
			    errorRef.current.style.display = 'block'
			});
		}else{
			axiosClient.patchRequest(constants.CALL_URL+'ivr/'+params.id, payload)
			.then(function (response) {
				toast.success('IVR Updated')	
			}).catch(function (error) {
				console.log(error)
			    errorRef.current.innerHTML = error.response.data.error
			    errorRef.current.style.display = 'block'
			});
		}
}


	return(
	<>
		<div class='header-body' style={{backgroundColor: 'white'}}>
	 		<a href='' style={{marginLeft:'20px', marginTop:'10px', textDecoration: 'none', fontSize:'16px'}} onClick={redirectToIVRList}> > All IVRs</a>
	 	</div>
		<div class='main-body' style={{top:'50px', height:'99vh'}}>
		  <div class="create-call-route-wrapper">
			<form onSubmit= {handleSubmit}>
			 <div style={{ padding:'30px', paddingleft:'0px',height: 'calc(100vh - 105px)' ,minWidth:'700px'}} >
              <h8 style={{color:'#044caf',fontWeight:'1000'}}><b>Configure IVR</b> </h8>
              <div class='create-line'></div>
				<div class='create-form-label' style={{marginTop: "3%"}}>IVR name: </div>
				<div><input type = "text" placeholder = "Enter IVR name" name='name'  class='custom-select' ref={nameTextboxRef}/></div>
				<div class='create-form-label' style={{marginTop: "3%"}}>Welcome Message</div>
				<div><input type='text' placeholder = 'Welcome Message'  required name='WelcomeMessage' class='custom-select' ref={messageTextboxRef}/></div>
				<div class='create-form-label' style={{marginTop: "3%"}}>Digit:</div>
				<div ref={conditionsRef}>
				{ ivrConditionLine.map((element, index) => 
					<IVRConditionLine element = {element} index={index}/>
					)					
				}
				</div>
				<div style={{marginTop: "3%"}}>
	            	<span ref={addIVRLink} onClick={addConditionsRef}       style={{  cursor: 'pointer'}} class='create-form-label'>Add Digit</span>
	            	<span ref={removeIVRLink} onClick={removeConditionsRef} style={{ cursor: 'pointer',marginLeft:'350px' }} class='create-form-label'>Remove Digit</span>
	            	<span></span>
	            </div>
				<div style={{marginTop:'30px' }}><button class='ss-button' onClick={redirectToIVRList}>Cancel</button><button class='ss-button' style={{marginLeft:' 350px' }}>{params.id !== undefined ? 'Update IVR' : 'Create IVR'}</button></div>
			  </div>
			</form>
			<div ref={errorRef} style={{fontSize:'16px', fontWeight:'500'}}/>			
		  </div>
		  		 		 
		</div>

		
			<ToastContainer position="top-right" autoClose={5000} hideProgressBar={false}
                newestOnTop={false} closeOnClick rtl={false} 
                draggable pauseOnHover theme="colored"/>		
	</>
	)


}

export default CreateIVR;