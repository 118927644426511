import React, { useState, useEffect,useRef } from 'react';
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants';
import {useNavigate, useParams} from "react-router-dom"

function SLACondition({ index, condition, onConditionChange , removeCondition}) {
  const fromInputRef = useRef(null)
  const [firstSelect, setFirstSelect] = useState(condition.firstSelect || 'Created By');
  const [fromSelection, setFromSelection] = useState(condition.fromSelection || 'Contact');
  const [inputValue, setInputValue] = useState(condition.inputValue || '');
  const [fromValue, setFromValue] = useState(condition.fromValue || '-1');
  const params = useParams(null)
  const INPUT_POS = 0
  const DROP_DOWN_POS = 1;
  const HIDDEN_INPUT = 2;
  const fromSelectionUrl = {
    Contact: constants.CONTACT_URL,
    Company: constants.COMPANY_URL,
    ContactGroup: constants.CONTACT_GROUP_URL,
    CompanyGroup: constants.COMPANY_GROUP_URL,
    User: constants.USER_URL,
    Team: constants.TEAM_URL,
  };

  let firstSelectMap = {
    "CreatedBy":'Created By',
    "AssignedTo": "Assigned To",
    "Channel":"Channel",
    "Type":"Type"
  }

  let fromSelectionMap={
    "ContactGroup":"Contact Group",
    "CompanyGroup": "Company Group",
    "AgentPortal": "Agent Portal",
    "CustomerPortal": "Customer Portal",
    "ServiceRequest":"Service Request",
    "ChangeRequest": "Change Request",
    "EmailTicketing":"Email Ticketing"
  }
 let filled = false
  useEffect(() => {   
    if(filled)
      return
    filled = true
     console.log('useEffect  '+JSON.stringify(condition)+"    "+fromSelectionUrl[condition.fromSelection])
    if(params.id && condition !== 1 && condition.fromSelection !== ""){
        setFirstSelect(firstSelectMap[condition.firstSelect])
        if(fromSelectionMap[condition.fromSelection])
          setFromSelection(fromSelectionMap[condition.fromSelection])
        else  
          setFromSelection(condition.fromSelection)
        setFromValue(condition.fromValue)
        if((condition.fromSelection === 'Contact' || condition.fromSelection === 'User')){
            if(condition.fromValue !== ""){
                  axiosClient.getRequest(fromSelectionUrl[condition.fromSelection] + condition.fromValue)
                .then(response => {
                  setInputValue(response.data.name);
                  // setFromSelection(condition.fromSelection)
                  // setFirstSelect(getFirstSelectOption(condition.fromSelection))
                })              
                .catch(error => console.error('Error fetching data:', error));      
            }
        }else{
          fromInputRef.current.children[0].disabled=true
        }
    }
  }, []);


function addChildToFromDropdown(event,element,type){
  console.log(" addChildToFromDropdown "+element.email)
    const DROP_DOWN_POS_CURR = 1;
    const HIDDEN_FIELD_CURR = 2;
    console.log("addChildToFromDropdown "+ event.target.outerHTML)
    let fromConditionDropDownRef = event.target.parentElement.children[DROP_DOWN_POS_CURR]
      let aTag = document.createElement('a')
      aTag.name=element.id      
      aTag.innerHTML = (element.fullName === undefined ? element.name : element.fullName )
      aTag.onclick = (event) => onSelectFromValueDropdown(element.id, element.name)
      fromConditionDropDownRef.appendChild(aTag)
      fromConditionDropDownRef.style.display='inline'
}
  const fetchFromData = (event) => {
     fromInputRef.current.children[DROP_DOWN_POS].innerHTML = ''
     fromInputRef.current.children[DROP_DOWN_POS].style.display = 'none' 
    const keyword = event.target.value;
    setInputValue(keyword);
    if (keyword.length < 3) return;

      // let fromSelection = document.getElementsByName('fromSelect-'+event.target.name.split('-')[1])[0]
      console.log('fromSelection '+fromSelection)
      axiosClient.getRequest(fromSelectionUrl[fromSelection] +"search_by_name?keyword="+event.target.value)
      .then(function (response) {
        response.data.forEach((element, index)=>{
            addChildToFromDropdown(event,element,fromSelection.value)
          })    
      }).catch(function (response) {
          console.log(response)
          console.log(response.data?.error)
      }); 
  };

  const handleFirstSelectChange = (e) => {
    const value = e.target.value;
    setFirstSelect(value);
    setFromSelection(getSecondSelectOptions(value)[0]);
    setInputValue('');
    let fromValueTemp = ''
    
    fromInputRef.current.children[DROP_DOWN_POS].innerHTML = ''
     fromInputRef.current.children[DROP_DOWN_POS].style.display = 'none' 
    if(value === 'Channel' || value === 'Type'){
      fromInputRef.current.children[0].disabled=true
      fromValueTemp = "-1"
    }
    else
      fromInputRef.current.children[0].disabled=false
    setFromValue(fromValueTemp)
    onConditionChange(index, { firstSelect: value,  inputValue: '', fromValue: fromValueTemp,fromSelection: getSecondSelectOptions(value)[0]  });
    fromInputRef.current.children[1].style.display = 'none'
  };

  const handleSecondSelectChange = (e) => {
    console.log('handleSecondSelectChange')
    const value = e.target.value;
    setFromSelection(value);
    let fromValueTemp = ''
    if(value === 'Any' || value === 'AnyOne' || firstSelect === 'Channel' || firstSelect === 'Type')
    {
      fromValueTemp = '-1'
      fromInputRef.current.children[0].disabled=true
    }else{
      fromValueTemp = ''
      fromInputRef.current.children[0].disabled=false
    }
    setInputValue('');
    setFromValue(fromValueTemp);
    onConditionChange(index, { firstSelect, fromSelection: value, inputValue: '', fromValue: fromValueTemp });
    fromInputRef.current.children[1].style.display = 'none'
  };

  const onSelectFromValueDropdown = (id, name) => {
    setFromValue(id);
    setInputValue(name);
    onConditionChange(index, { firstSelect, fromSelection, inputValue: name, fromValue: id });
    fromInputRef.current.children[1].style.display = 'none'
    fromInputRef.current.children[1].innerHTML=''
  };

  const getSecondSelectOptions = (value) => {
    if(!value)
      value = firstSelect
    switch (value ) {
      case 'Created By':
        return ['Contact', 'Company', 'Contact Group', 'Company Group', 'AnyOne'];
      case 'Assigned To':
        return ['User', 'Team', 'AnyOne'];
      case 'Channel':
        return ['Email Ticketing', 'Agent Portal', 'Customer Portal', 'Call', 'Chat','Any'];
      case 'Type':
        return ['Incident', 'Service Request', 'Change Request', 'Payment', 'Escalation','Any'];
      default:
        return [];
    }
  };

const getFirstSelectOption = (selectedValue) => {
  const optionsMap = {
    'Created By': ['Contact', 'Company', 'Contact Group', 'Company Group', 'AnyOne'],
    'Assigned To': ['User', 'Team', 'AnyOne'],
    'Channel': ['Email Ticketing', 'Agent Portal', 'Customer Portal', 'Call', 'Chat', 'Any'],
    'Type': ['Incident', 'Service Request', 'Change Request', 'Payment', 'Escalation', 'Any']
  };

  for (const [key, values] of Object.entries(optionsMap)) {
    if (values.includes(selectedValue)) {
      return key;
    }
  }

  return null; // Return null if no match is found
};
  return (
    <div style={{ with:' 100%', paddingTop: '1px', display: 'flex', alignItems:'flex-end' }}>
      {/* First Dropdown */}
      <div style={{ width: '200px', fontWeight: 'bold', paddingTop: '5px', fontSize: '16px' }}>
        Condition Type
        <select value={firstSelect} onChange={handleFirstSelectChange} className="sla-condition-input" style={{width: '200px'}}>
          <option value="Created By">Created By</option>
          <option value="Assigned To">Assigned To</option>
          <option value="Channel">Channel</option>
          <option value="Type">Type</option>
        </select>
      </div>

      {/* Second Dropdown */}
      <div style={{width: '200px', fontWeight: 'bold', marginLeft: '2%', paddingTop: '5px', fontSize: '16px' }}>
        Value
        <select value={fromSelection} onChange={handleSecondSelectChange} className="sla-condition-input" disabled={!firstSelect} name= {'fromSelect-'+index} style={{width: '200px'}}>
          {getSecondSelectOptions().map((option, index) => (
            <option key={index} value={option}>{option}</option>
          ))}
        </select>
      </div>
      <div style={{width: 'calc(100% - 300px)', fontWeight: 'bold',position: 'relative', marginLeft: '2%',  paddingTop: '10px', fontSize: '16px' }} ref={fromInputRef}>
        Value
        <input
          value={inputValue}
          placeholder="Search ..."
          onChange={fetchFromData}
          className="sla-condition-input"
          name={'fromInput-'+index+'-hidden'}
          style={{width: '100%'}}
        />
        <div class="sla-condition-dropdown-content" style={{display: 'none' }}/>
      </div>      
      <div><button class='ss-button' onClick={(e)=>removeCondition(e, index)}>&times;</button></div>
    </div>
  );
}

export default SLACondition;
