import {useState, setState, useRef, useEffect} from 'react';
import ReplyBox from './ReplyBox'
import * as axiosClient from '../../../js/Axios';
import * as constants from '../../../js/constants'
import {useNavigate} from "react-router-dom"
import ResolutionLine from './ResolutionLine'

function TicketResolutions(props){
	const [ticket, setTicket] = useState(null)  
	const replyBoxRef = useRef(null)	
	const commentRef = useRef(null)
	const navigate = useNavigate();
	const [commentLine, setCommentLine] = useState([])
	const errorRef = useRef(null)
	const spinnerRef = useRef(null)
  	const [attachmentFilesList, setAttachmentFilesList] = useState([])
  	const [resolutions, setResolutions] = useState(null)

useEffect(() => {        
  console.log('useEffect in TicketResolutions '+JSON.stringify(props.ticket.resolutions))
  setTicket(props.ticket)
  if(props.ticket.resolutions !== undefined)
    setResolutions(props.ticket.resolutions)                           
},[])//use effect

function closeReplyBox(){
    props.replyBoxRef.current.style.display = 'none'
}
function removeAttachmentFromEdit(id){
    let attachmentFilesListArr = [...attachmentFilesList]
    attachmentFilesListArr.splice(id, 1);
    setAttachmentFilesList(attachmentFilesListArr)
}
function format(event,type){
  // getSelectionText()
  commentRef.current.focus();
  if(event.target.parentElement.style.backgroundColor === '')  
    event.target.parentElement.style.backgroundColor = '#dddddd'
  else
    event.target.parentElement.style.backgroundColor = ''
  document.execCommand(type, false, null);
  commentRef.current.focus();  
}

function addResolution(event, message,to){
  errorRef.current.innerHTML = ''
  event.target.disabled = true
  if(message === '' && attachmentFilesList.length === 0){
      event.target.disabled = false
        return
  }

    spinnerRef.current.style.display = 'inline-block'

    let config ={}         
    const formData = new FormData();
    const filesMap = {}
    let sizes = []
    attachmentFilesList.forEach(file=>{
      formData.append("files", file.name);
      formData.append("sizes", file.size);
      formData.append("types", file.type);
      filesMap[file.name] = file
    });
    formData.append('ticketId', props.ticket.id)
    formData.append('content', message)

    
	axiosClient.postRequest(constants.TICKET_URL+'resolution/'+props.ticket.id, formData,config)
    .then(function (response) {       
        let attachmentList = response.data.attachments
        if(attachmentList !== null){
          let promises = [];
          for (var i = 0; i < response.data.attachments.length; i++) {
            promises.push(
              axiosClient.putExternalRequest(response.data.attachments[i].link,filesMap[response.data.attachments[i].name],{headers: {
                'Content-Type': 'multipart/form-data',
                'Content-Length': filesMap[response.data.attachments[i].name].size
              }}).then(response => {}))
            }
          Promise.all(promises).then(() => {
              setResolutions([...resolutions, response.data])
              commentRef.current.innerHTML = ''
              setAttachmentFilesList([])
              event.target.disabled = false
              spinnerRef.current.style.display = 'none'   
              props.updateResolutions(response.data)         
          });
        }else{
              setResolutions([...resolutions, response.data])
              commentRef.current.innerHTML = ''
              setAttachmentFilesList([])
              event.target.disabled = false
              spinnerRef.current.style.display = 'none'   
              props.updateResolutions(response.data)                 
        }        
    	}).catch((error) => {
        		console.log(error)
        event.target.disabled = false
        spinnerRef.current.style.display = 'none'
    	});
}

function handleAttachment(event){
  console.log('handleAttachment '+attachmentFilesList.length)
    errorRef.current.innerHTML = ''
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    if( event.target.files[0].size > (1000 *1000 *1000))
    {
        errorRef.current.innerHTML = 'Maximum file size is 1GB'
        return;
    }
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}
function handleAttachment(event){
  console.log('handleAttachment '+attachmentFilesList.length)
    errorRef.current.innerHTML = ''
    event.preventDefault();
    if(Object.keys(event.target.files).length === 0){
      return
    }
    if( event.target.files[0].size > (1000 *1000 *1000))
    {
        errorRef.current.innerHTML = 'Maximum file size is 1GB'
        return;
    }
    setAttachmentFilesList([...attachmentFilesList,event.target.files[0]])
}
function deleteClicked(){}


  return (
  		<div class="ticket-middle-box" style={{width:' 100%'}}>
          {
          	resolutions === null ? '' :
            resolutions.map((comment,index) => 
              <ResolutionLine comment={comment} content={comment.content} deleteClicked={deleteClicked}/>
            )
          }
  		 <ReplyBox replyBoxRef={props.replyBoxRef} ticket={ticket} closeReplyBox={closeReplyBox}
                              commentRef={commentRef} removeAttachmentFromEdit={removeAttachmentFromEdit} handleAttachment={handleAttachment}
                              format={format} addComment={addResolution} spinnerRef={spinnerRef} errorRef={errorRef} buttonText={'Resolution'}
                              attachmentFilesList={attachmentFilesList}
                              handleAttachment={handleAttachment}/>
  		</div>
    )
}

export default TicketResolutions;