import React from 'react';
import Select from 'react-select';
import '../css/draggable.css';

function DynamicForm(props) {
  console.log('DynamicForm '+JSON.stringify(props.customFields))

  const handleChange = (e, fieldId) => {
    props.setFormData((prevData) =>
      prevData.map((field) => {
        if (field.id === fieldId) {
          let updatedField = { ...field };
          // Handle multi-select from react-select
          if (Array.isArray(e)) {
            const selectedValues = e.map((option) => option.value).join(";");
            return {
              ...field,
              formValues: selectedValues,
            };
          }

          // Handle other field types
          const { type, checked, value } = e.target;
          if (type === 'checkbox') {
            const customType = e.target.getAttribute('data-custom-type');
            if (customType === 'checkbox-group') {
              const selectedValues = field.formValues || '';
              return {
                ...field,
                formValues: checked
                  ? selectedValues + ";" + value
                  : selectedValues.split(value).join(''),
              };
            } else if (field.type === 'checkbox') {
              return { ...field, formValues: checked };
            }
          } else {
            return {
              ...field,
              formValues: value,
            };
          }
        }
        return field;
      })
    );
  };



  return (
    <>
      {props.customFields?.map((field, index) => (
        <div key={index} style={{ marginTop: "3%" }}>
          <label>
            {field.type !== 'checkbox' ? field.apLabel : ''}
            {field.type !== 'checkbox' ? <span style={{ color: 'red' }}> {(field.apMandatorySubmission === 'true') && " *"}</span> : ''}
          </label>
          <br />

          {field.type === "textbox" || field.type === "number" || field.type === "email" || field.type === "date" || field.type === "datetime" || field.type === "decimal" ? (
            <input
              type={field.type === 'datetime' ? 'datetime-local' : (field.type === 'decimal' ? 'number' : field.type)}
              name={field.apLabel}
              placeholder={field.placeholder === 'null' ? '' : field.placeholder}
              required={field.apMandatorySubmission === 'true'}
              style={{ width: "90%" }}
              step={field.type === "decimal" ? "0.1" : undefined}
              onChange={(e) => handleChange(e, field.id)}
              value={props.formData.find((f) => f.id === field.id)?.formValues || ''}
              disabled={props.edit && field.apEditable}
            />
          ) : field.type === "dropdown" ? (
            <select
              name={field.apLabel}
              style={{ width: '90%', padding: '5px', borderRadius: '5px' }}
              onChange={(e) => handleChange(e, field.id)}
              value={props.formData.find((f) => f.id === field.id)?.formValues || ''}
              disabled={props.edit && field.apEditable}
              required={field.apMandatorySubmission === 'true'}
            >
              <option value="">Select {field.label}</option>
              {field.values.split(";").map((option, idx) => (
                <option key={idx} value={option}>
                  {option}
                </option>
              ))}
            </select>
          ) : field.type === "dropdown-multi" ? (
            <Select
              isMulti
               disabled={props.edit && field.apEditable}
              options={field.values.split(";").map((option) => ({ value: option, label: option }))}
              onChange={(selectedOptions) => handleChange(selectedOptions, field.id)}
              value={
                props.formData.find((f) => f.id === field.id)?.formValues
                  ? props.formData.find((f) => f.id === field.id).formValues.split(";").map((value) => ({ value, label: value }))
                  : []
              }
              placeholder={`Select ${field.apLabel}`}
              closeMenuOnSelect={false}
              required={field.apMandatorySubmission === 'true'}
              styles={{
                control: (base) => ({
                  ...base,
                  height: '30px', // Adjust control height
                  width: '90%', // Adjust width of input area
                  border: '1px solid #909196', // Set custom border for control
                }),
                menu: (base) => ({
                  ...base,
                  width: '90%', // Set dropdown menu width to 90% of the container
                }),
                multiValue: (base) => ({
                  ...base,
                  backgroundColor: '#e0e0e0', // Optional: Custom background for selected items
                }),
                multiValueLabel: (base) => ({
                  ...base,
                  color: 'black', // Optional: Custom color for label in selected items
                }),
                multiValueRemove: (base) => ({
                  ...base,
                  color: 'red', // Optional: Custom remove button color
                  ':hover': {
                    backgroundColor: 'transparent', // Optional: Custom hover effect
                    color: 'darkred', // Optional: Custom hover color
                  },
                }),
              }}
            />
          ) : field.type === "checkbox" ? (
            <>
              <input
                type="checkbox"
                name={field.apLabel}
                required={field.apMandatorySubmission === 'true'}
                data-custom-type={field.type}
                onChange={(e) => handleChange(e, field.id)}
                checked={field.formValues === true}
                disabled={props.edit && field.apEditable}
              />
              <span style={{ marginLeft: '5px' }}> {field.apLabel} </span>
              <span style={{ color: 'red' }}> {(field.apMandatorySubmission === 'true') && " *"}</span>
            </>
          ) : field.type === "checkbox-group" ? (
            field.values.split(";").map((option, idx) => (
              <span key={idx} style={{ marginTop: '3%', verticalAlign: 'center', marginLeft: '5px' }}>
                <input
                  type="checkbox"
                  data-custom-type='checkbox-group'
                  name={option}
                  value={option}
                  checked={props.formData.find((f) => f.id === field.id)?.formValues?.split(';').includes(option)}
                  style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                  onChange={(e) => handleChange(e, field.id)}
                  disabled={props.edit &&  field.apEditable}
                />
                <span style={{ marginLeft: '5px', fontSize: '14px' }}> {option}</span>
              </span>
            ))
          ) : field.type === "radio" ? (
            field.values.split(";").map((option, idx) => (
              <span key={idx} style={{ marginTop: '3%', marginLeft: '5px' }}>
                <input
                  disabled={props.edit && field.apEditable}
                  type="radio"
                  name={field.apLabel}
                  value={option}
                  checked={props.formData.find((f) => f.id === field.id)?.formValues === option}
                  style={{ verticalAlign: 'middle', marginLeft: '5px' }}
                  onChange={(e) => handleChange(e, field.id)}
                  required={field.apMandatorySubmission === 'true'}
                />
                <span style={{ marginLeft: '5px', fontSize: '16px', fontWeight: '500' }}> {option}</span>
              </span>
            ))
          ) : field.type === "textarea" ? (
            <textarea
              disabled={props.edit &&  field.apEditable}
              rows='4'
              name={field.apLabel}
              required={field.apMandatorySubmission === 'true'}
              style={{ width: "90%", height: "100px", color: 'black' }}
              value={props.formData.find((f) => f.id === field.id)?.formValues || ''}
              onChange={(e) => handleChange(e, field.id)}
            />
          ) : null}
        </div>
      ))}
    </>
  );
}

export default DynamicForm;
